import React, { FC } from "react";
import { CircleLoader } from "react-spinners";

const Loader: FC = () => {
    return (
        <div className="loader_container">
            <CircleLoader size={100} color="#6f9a37" />
        </div>
    );
};

export default Loader;