import React from "react";
import ReactDOM from "react-dom";
import { legacy_createStore, applyMiddleware, compose, combineReducers } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import authReducer from "./store/reducers/authReducer";
import usersReducer from "./store/reducers/usersReducer";
import menuReducer from "./store/reducers/menuReducer";
import settingsReducer from "./store/reducers/settingsReducer";
import App from "./App";
import { ToastContainer } from "react-toastify";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import "./main.css";
import "react-toastify/dist/ReactToastify.css";

//Declare the global window interface to include Redux DevTools
declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
    }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

//Combine reducers
const rootReducer = combineReducers({
    auth: authReducer,
    users: usersReducer,
    menu: menuReducer,
    settings: settingsReducer
});

const store = legacy_createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(thunk))
);

//Type definitions for app dispatch and root state
export type AppDispatch = typeof store.dispatch;
export type state = ReturnType<typeof rootReducer>;

ReactDOM.render(
    <Provider store={store}>
        <React.StrictMode>
            <ToastContainer autoClose={2000} />
            <App />
        </React.StrictMode>
    </Provider>,
    document.getElementById("root")
);