import React, { FC, useState } from "react";
import { setDoc, doc } from "firebase/firestore";
import { db } from "../../config/firebase";
import { v4 as uuidv4 } from "uuid";
import { DisabledOatmealDates } from "../../store/reducers/menuState";
import moment, { Moment } from "moment";
import DatePicker from "../UI/DatePicker";
import Button from "../UI/Button";
import { Save } from "@mui/icons-material";
import { toast } from "react-toastify";

interface OatmealDisableDates {
    startDate: Moment;
    endDate: Moment;
}

const DisableOatmealOrderingForm: FC = () => {
    const [disableDates, setDisableDates] = useState<OatmealDisableDates>({
        startDate: moment(),
        endDate: moment()
    });

    const handleStartDateChange = (date: Moment): void => {
        if (disableDates.endDate < date) {
            setDisableDates({ ...disableDates, startDate: date, endDate: date });
        } else {
            setDisableDates({ ...disableDates, startDate: date });
        }
    };

    const handleEndDateChange = (date: Moment): void => {
        if (disableDates.startDate > date) {
            setDisableDates({ ...disableDates, startDate: date, endDate: date });
        } else {
            setDisableDates({ ...disableDates, endDate: date });
        }
    };

    const handleSaveDisableDates = async (): Promise<void> => {
        const id: string = uuidv4();
        const date: DisabledOatmealDates = {
            startDate: disableDates.startDate.startOf("day").toISOString(),
            endDate: disableDates.endDate.endOf("day").toISOString()
        };

        try {
            await setDoc(doc(db, "disabledOatmealDates", id), date);
            toast.success("Usprešno ste sačuvali datume.");
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div>
            <div className="input_container">
                <DatePicker
                    label="Od"
                    value={disableDates.startDate}
                    onChange={handleStartDateChange}
                />
                <DatePicker
                    label="Do"
                    value={disableDates.endDate}
                    onChange={handleEndDateChange}
                />
                <Button
                    label="Sačuvaj"
                    startIcon={<Save />}
                    className="btn_success"
                    handleClick={handleSaveDisableDates}
                />
            </div>
        </div >
    );
};

export default DisableOatmealOrderingForm;