import React, { FC } from "react";
import { FormControl, TextField } from "@mui/material";

interface InputProps {
    label: string;
    type: string;
    value: string | number;
    multiline?: boolean;
    rows?: number;
    disabled?: boolean;
    name?: string;
    required?: boolean;
    error?: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onKeyUp?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}

const Input: FC<InputProps> = ({ label, type, value, multiline, rows, disabled, name, required = false, error, onChange, onKeyUp, }) => {
    return (
        <FormControl fullWidth sx={{ my: 1.5 }}>
            <TextField
                label={label}
                type={type}
                value={value}
                multiline={multiline}
                rows={rows}
                disabled={disabled}
                name={name}
                required={required}
                onChange={onChange}
                onKeyUp={onKeyUp}
                variant="outlined"
                size="medium"
                sx={{
                    "& label.Mui-focused": {
                        color: "#6c757d"
                    },
                    "& .MuiFormLabel-asterisk": {
                        color: "#ff0000"
                    },
                    "& .MuiOutlinedInput-root": {
                        background: "#fff",
                        "&:hover fieldset": {
                            border: "1px solid #6c757d"
                        },
                        "&.Mui-focused fieldset": {
                            border: "1px solid #6c757d"
                        }
                    }
                }}
            />
            {error && <div className="error_message">{error}</div>}
        </FormControl>
    );
};

export default Input;