import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { state } from "../../index";
import * as menuActions from "../../store/actions/menuActions";
import menuState, { Meal } from "../../store/reducers/menuState";
import { Order, SelectedMeal, portionLabels } from "../Menu/Menu";
import { calculateTotalMealsSum, calculateTotalOrderAmount, groupMealsByMealId, formatCurrency } from "../../helpers/utils";
import moment from "moment";
import "moment/locale/sr";
import Loader from "../UI/Loader";
import Button from "../UI/Button";
import Container from "react-bootstrap/Container";
import { Badge, Box, Divider, Tabs, Tab, ListItem, IconButton, SvgIconProps } from "@mui/material";
import { AccessTime, CheckCircle, LocalDining, Phone, Place, PowerSettingsNew, NextPlan, RotateLeft } from "@mui/icons-material";

export interface GroupOrders {
    [key: string]: Order[];
}

export interface GroupMeals {
    [key: string]: SelectedMeal[];
}

const tabLabels: string[] = ["U pripremi", "Isporučeno", "Proizvodi", "Naredni dan"];

const Orders: FC = () => {
    const [tabValue, setTabValue] = useState<number>(0);

    const dispatch = useDispatch();
    const {
        fetchOrders, fetchIncomingOrders, fetchMenu,
        setIncomingOrdersUnsubscribe, setOrdersUnsubscribe,
        resetOrdersUnsubscribe, resetIncomingOrdersUnsubscribe,
        updateToFinishedOrder, updateMealToSoldout
    } = bindActionCreators(menuActions, dispatch);

    const menuState: menuState = useSelector((state: state) => state.menu);
    const orders: Order[] = menuState.orders;
    const incomingOrders: Order[] = menuState.incomingOrders;

    useEffect(() => {
        const fetchOrdersUnsubscribe = fetchOrders();
        const fetchIncomingOrdersUnsubscribe = fetchIncomingOrders();
        fetchMenu(moment().startOf("day").unix());

        //Clean up the listener when the component is unmounted
        return () => {
            //Reset the unsubscribe function in the Redux state
            resetIncomingOrdersUnsubscribe();
            resetOrdersUnsubscribe();

            //Unsubscribe from the Firebase listeners
            setOrdersUnsubscribe(fetchOrdersUnsubscribe);
            setIncomingOrdersUnsubscribe(fetchIncomingOrdersUnsubscribe);
        };
    }, []);

    const getTabTitle = (): string => {
        const titles: string[] = [
            "Nema porudžbina za danas",
            "Nema isporučenih porudžbina",
            "Današnja jela",
            "Jela za naredni dan"
        ];

        return titles[tabValue] || "";
    };

    const getTabIcon = (index: number): JSX.Element => {
        const icons: Array<React.ElementType<SvgIconProps>> = [
            RotateLeft,
            CheckCircle,
            PowerSettingsNew,
            NextPlan
        ];
        const IconComponent = icons[index];

        return <IconComponent /> || null;
    };

    const handleChangeTabValue = (e: React.SyntheticEvent, newValue: number): void => {
        setTabValue(newValue);
    };

    const handleUpdateToFinishedOrder = (orderId: string): void => {
        updateToFinishedOrder(orderId);
    };

    const handleUpdateMealToSoldout = (mealId: string): void => {
        const meals = [...menuState.menu?.meals];
        const mealIndex = meals.findIndex(meal => meal.id === mealId);
        if (mealIndex !== -1) {
            meals[mealIndex].soldOut = true;
            updateMealToSoldout(menuState.menu.id, meals);
        }
    };

    if (menuState.loading) return <Loader />;

    const groupedMeals: SelectedMeal[][] = groupMealsByMealId(incomingOrders);

    const filteredOrders: Order[] = orders.filter(order => order.orderStatus === (tabValue === 0 ? "preparation" : tabValue === 1 ? "finished" : ""));
    const groupByCustomerId = Object.values(filteredOrders.reduce((acc: GroupOrders, order: Order) => {
        if (!acc[order.customerId]) acc[order.customerId] = [];
        acc[order.customerId].push(order);
        return acc;
    }, {}));

    return (
        <div>
            <Container style={{ marginTop: 40 }}>
                <Box
                    sx={{
                        "& .MuiTabs-indicator": {
                            background: "#fff"
                        }
                    }}
                >
                    <Tabs
                        value={tabValue}
                        onChange={handleChangeTabValue}
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="basic tabs example"
                    >
                        {tabLabels.map((label: string, i: number) => (
                            <Tab
                                key={i}
                                label={label}
                                icon={getTabIcon(i)}
                                iconPosition="start"
                                sx={{
                                    background: "#fff",
                                    color: "#818181",
                                    border: "1px solid #e5e5e5",
                                    mr: 1.5,
                                    minWidth: "170px",
                                    minHeight: "auto",
                                    padding: "6px 16px",
                                    "&.MuiTab-root.Mui-selected": {
                                        background: "#5C5E60",
                                        color: "#fff"
                                    }
                                }}
                            />
                        ))}
                    </Tabs>
                </Box>

                {groupByCustomerId.length > 0 ?
                    groupByCustomerId.map((orders: Order[], index: number) => {
                        return (
                            <div key={index}>
                                <Box className="order_heading">
                                    <div className="order_customer_basic_info">
                                        <Badge badgeContent={orders.length} color="error">
                                            <LocalDining />
                                        </Badge>
                                        <h3>{orders[0].customerName}</h3>
                                        <p>
                                            <AccessTime sx={{ mx: 1 }} />
                                            <span className="delivery_time">{orders[0].deliveryTime} h</span>
                                        </p>
                                    </div>
                                    <div className="order_customer_additional_info">
                                        <p>
                                            <Place />
                                            <span>{orders[0].address}</span>
                                        </p>
                                        <p>
                                            <Phone />
                                            <span>{orders[0].phoneNumber}</span>
                                        </p>
                                    </div>
                                </Box>
                                <div className="orders_container">
                                    {orders.map((o: Order, i: number) => (
                                        <div key={i} className={`single_order ${orders[0].orderStatus === "preparation" ? "preparation" : "finished"}`}>
                                            <div className="single_order_heading_container">
                                                <h4>{o.createdUserFullName}</h4>
                                                {tabValue === 0 && <Button
                                                    label="Spremno"
                                                    startIcon={<CheckCircle />}
                                                    className="order_finished_btn"
                                                    handleClick={() => { handleUpdateToFinishedOrder(o.id) }}
                                                />}
                                            </div>
                                            {o.meals.map((meal: SelectedMeal, i: number) => (
                                                <div key={i} className={`order_list ${meal.mealType === "oatmeal" ? "oatmeal_order" : ""}`}>
                                                    <b>{meal.mealNumber}. </b>
                                                    {meal.name} <b>{portionLabels[meal.size] && `${portionLabels[meal.size]}`} </b>
                                                    <span>x {meal.amount}</span>
                                                </div>
                                            ))}
                                            {o.note &&
                                                <div><b>Napomena:</b> {o.note}</div>
                                            }
                                        </div>
                                    ))}
                                    {tabValue === 1 &&
                                        <div className="order_total_amount">
                                            Ukupno: {formatCurrency(calculateTotalOrderAmount(orders))} RSD
                                        </div>
                                    }
                                </div>
                            </div>
                        );
                    })
                    :
                    <Divider sx={{ my: 2 }}>
                        <h3 className="main_title">{getTabTitle()}</h3>
                    </Divider>
                }

                {tabValue === 2 &&
                    <div>
                        {menuState.menu?.meals?.map((meal: Meal, i: number) =>
                            <React.Fragment key={meal.id}>
                                <ListItem
                                    inputMode="numeric"
                                    divider
                                    sx={{ maxWidth: 760, bgcolor: meal.soldOut ? "#f1f1f1" : "#fff", mb: 2.5 }}
                                    secondaryAction={
                                        <IconButton
                                            edge="end"
                                            aria-label="remove"
                                            disabled={meal.soldOut}
                                            onClick={() => { handleUpdateMealToSoldout(meal.id) }}
                                        >
                                            <PowerSettingsNew
                                                sx={{ color: meal.soldOut ? "#5C5E60" : "#4eaf53" }}
                                            />
                                        </IconButton>
                                    }
                                >
                                    <h5>{i + 1}. {meal.name}</h5>
                                </ListItem>
                            </React.Fragment>
                        )}
                    </div>
                }

                {tabValue === 3 &&
                    <div>
                        <Box className="order_heading">
                            <div className="order_customer_basic_info">
                                <Badge badgeContent={calculateTotalMealsSum(groupedMeals)} color="error">
                                    <LocalDining />
                                </Badge>
                                <h3>Ukupan broj jela</h3>
                            </div>
                        </Box>
                        <div className="orders_container">
                            {groupedMeals.map((mealGroup: SelectedMeal[], i) => {
                                const totalAmount: number = mealGroup.reduce((total: number, meal: SelectedMeal) => total + meal.amount, 0);
                                return (
                                    <div key={i} className={`single_order ${mealGroup[0]?.mealType === "oatmeal" ? "oatmeal_order" : ""}`}>
                                        <b>{mealGroup[0].mealNumber}. </b>{mealGroup[0].name} <span>x {totalAmount}</span>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                }
            </Container>
        </div >
    );
}

export default Orders;