import React, { FC } from "react";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import * as settingsActions from "../../../store/actions/settingsActions";
import { Meal } from "../../../store/reducers/menuState";
import { Box, Button, Divider, Modal, ModalDialog, Typography } from "@mui/joy";
import { Delete, WarningRounded } from "@mui/icons-material";

interface DeleteMealModalProps {
    open: boolean;
    selectedMeal: Meal;
    onHandleClose: () => void;
}

const DeleteMealModal: FC<DeleteMealModalProps> = ({ open, selectedMeal, onHandleClose }) => {
    const dispatch = useDispatch();
    const { deleteMeal } = bindActionCreators(settingsActions, dispatch);

    const handleDeleteMeal = (e: React.FormEvent): void => {
        e.preventDefault();
        deleteMeal(selectedMeal);
        onHandleClose();
    };

    return (
        <Modal open={open} onClose={onHandleClose}>
            <ModalDialog
                variant="outlined"
                role="alertdialog"
                aria-labelledby="alert-dialog-modal-title"
                aria-describedby="alert-dialog-modal-description"
            >
                <Typography id="alert-dialog-modal-title" level="h2" startDecorator={<WarningRounded />}>
                    Brisanje jela
                </Typography>
                <Divider />
                <Typography id="alert-dialog-modal-description" textColor="text.tertiary">
                    Da li ste sigurni da želite da obrišete jelo <b>{selectedMeal.name}</b>?
                </Typography>
                <Box sx={{ display: "flex", gap: 1, justifyContent: "flex-end", pt: 2 }}>
                    <Button
                        variant="plain"
                        color="neutral"
                        onClick={() => onHandleClose()}
                    >
                        Otkaži
                    </Button>
                    <Button
                        variant="solid"
                        color="danger"
                        startDecorator={<Delete />}
                        onClick={handleDeleteMeal}
                    >
                        Obriši jelo
                    </Button>
                </Box>
            </ModalDialog>
        </Modal>
    );
}

export default DeleteMealModal;