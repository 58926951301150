import React, { FC, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { state } from "../../index";
import * as menuActions from "../../store/actions/menuActions";
import authState from "../../store/reducers/authState";
import menuState from "../../store/reducers/menuState";
import { Order, SelectedMeal, portionLabels } from "./Menu";
import { formatCurrency, calculateUserSubvention } from "../../helpers/utils";
import { Moment } from "moment";
import "moment/locale/sr";
import Input from "../UI/Input";
import Button from "../UI/Button";
import { Badge, Drawer, IconButton } from "@mui/material";
import { Add, Done, Delete, Remove, ShoppingCart, Close } from "@mui/icons-material";
import { toast } from "react-toastify";

interface SidebarProps {
    selectedDate: Moment;
    selectedMeals: SelectedMeal[];
    totalAmount: number;
    addItemFromCart: (meal: SelectedMeal) => void;
    removeItemFromCart: (meal: SelectedMeal) => void;
    saveOrder: (note: string, subvention: number) => void;
    emptyCart: () => void;
}

const Sidebar: FC<SidebarProps> = ({ selectedDate, selectedMeals, totalAmount, addItemFromCart, removeItemFromCart, saveOrder, emptyCart }) => {
    const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
    const [note, setNote] = useState<string>("");

    const dispatch = useDispatch();
    const { fetchUserOrders } = bindActionCreators(menuActions, dispatch);

    const authState: authState = useSelector((state: state) => state.auth);
    const menuState: menuState = useSelector((state: state) => state.menu);
    const userId: string = authState.userUid;
    const userOrders: Order[] = menuState.userOrders;

    useEffect(() => {
        fetchUserOrders(selectedDate.format("DD/MM/YYYY"), userId);
    }, []);

    const handleDrawerToggle = () => {
        setDrawerOpen(!drawerOpen);
    };

    const handleEmptyCart = (): void => {
        setNote("");
        emptyCart();
    };

    const handleSaveOrder = (): void => {
        const extraPotatoeOrdered: boolean = selectedMeals.some(meal => meal.mealType === "extraPotatoe");
        //Check if extra potatoe is ordered and note is empty
        if (extraPotatoeOrdered && !note.trim()) {
            toast.error("Molimo vas unesite u napomenu da li želite pomfrit ili začinski krompir.");
            return;
        }

        setNote("");
        setDrawerOpen(false);
        saveOrder(note, subvention);
    }

    const numberOfCartItems: number = selectedMeals.reduce((acc, item) => { return acc + item.amount; }, 0);
    const subvention: number = calculateUserSubvention(authState.user.customerSubvention, userOrders, totalAmount);

    return (
        <div className="sidebar_container">
            <Badge
                badgeContent={numberOfCartItems}
                color="error"
                className={drawerOpen ? "shift_cart_left" : "shift_cart_right"}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "left"
                }}
                sx={{
                    position: "fixed",
                    zIndex: 999
                }}
            >
                <Button
                    endIcon={<ShoppingCart />}
                    className="btn_success"
                    handleClick={handleDrawerToggle}
                />
            </Badge>

            <Drawer
                anchor="right"
                open={drawerOpen}
                variant="persistent"
                onClose={handleDrawerToggle}
                PaperProps={{
                    sx: {
                        width: { xs: "100%", sm: "450px" },
                        background: "#5C5E60",
                        color: "#fff"
                    }
                }}
            >
                <div className="sidebar_header">
                    <h2>{selectedDate.format("dddd, DD. MM.")}</h2>
                    <IconButton
                        onClick={() => setDrawerOpen(false)}
                    >
                        <Close sx={{ color: "#fff" }} />
                    </IconButton>
                </div>
                <div className="sidebar_content">
                    {selectedMeals.map(meal =>
                        <div className="sidebar_item" key={meal.portionId}>
                            <h2 className="meal_name">{meal.name}</h2>
                            <p className="meal_size">{portionLabels[meal.size] && `${portionLabels[meal.size]}:`}
                                <b className="amount">{formatCurrency(meal.price)} RSD</b>
                            </p>
                            <div className="meal_ordering">
                                <Button
                                    endIcon={<Remove />}
                                    handleClick={() => { removeItemFromCart(meal) }}
                                />
                                <p className="meal_amount">{meal.amount}</p>
                                <Button
                                    endIcon={<Add />}
                                    handleClick={() => { addItemFromCart(meal) }}
                                />
                            </div>
                        </div>
                    )}
                    <div className="total_amount_container">
                        <div className="total_amount">
                            <span>Ukupno</span>
                            <b className={`amount ${(subvention > 0 && selectedMeals.length) && "strikethrough"}`}>{formatCurrency(totalAmount)} RSD</b>
                        </div>
                        {(subvention > 0 && selectedMeals.length) &&
                            <div className="total_amount">
                                <span>Sa subvencijom</span>
                                <b className="amount">{formatCurrency(totalAmount - subvention)} RSD</b>
                            </div>
                        }
                    </div>
                    <Input
                        label="Napomena"
                        type="text"
                        value={note}
                        multiline
                        rows={5}
                        name="note"
                        onChange={(e) => { setNote(e.target.value) }}
                    />
                </div>

                <div className="sidebar_btns_container">
                    <Button
                        label="Obriši"
                        endIcon={<Delete />}
                        disabled={selectedMeals.length === 0}
                        className="btn_disabled"
                        handleClick={handleEmptyCart}
                    />
                    <Button
                        label="Poruči"
                        endIcon={<Done />}
                        disabled={selectedMeals.length === 0}
                        className="btn_success"
                        handleClick={handleSaveOrder}
                    />
                </div>
            </Drawer>
        </div>
    );
}

export default Sidebar;