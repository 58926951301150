import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import { AnyAction, Dispatch } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { db } from "../../config/firebase";
import { collection, updateDoc, query, where, setDoc, DocumentData, Query, doc, onSnapshot, orderBy, QuerySnapshot, getDocs, deleteDoc, DocumentReference } from "firebase/firestore";
import * as actionTypes from "../types/menuActionTypes";
import { DisabledOatmealDates, Meal, Menu } from "../reducers/menuState";
import { Order } from "../../components/Menu/Menu";
import { toast } from "react-toastify";

export const fetchMenuRequest = () => ({
    type: actionTypes.FETCH_MENU_REQUEST
});

export const fetchMenuSuccess = (data: Menu) => ({
    type: actionTypes.FETCH_MENU_SUCCESS,
    payload: data
});

export const fetchMenuFail = (error: any) => ({
    type: actionTypes.FETCH_MENU_FAIL,
    payload: error
});

export const fetchMenu = (date: number) => {
    return (dispatch: Dispatch) => {
        dispatch(fetchMenuRequest());
        try {
            const menuQuery: Query<DocumentData> = query(collection(db, "menu"), where("date", "==", date));
            onSnapshot(menuQuery, (snapshot) => {
                const menuData: Menu = snapshot.docs.map(doc => ({ ...doc.data() as Menu }))[0];
                dispatch(fetchMenuSuccess(menuData));
            });
        } catch (error) {
            console.error(error);
            dispatch(fetchMenuFail(error));
        }
    };
};

export const saveOrderRequest = () => ({
    type: actionTypes.SAVE_ORDER_REQUEST
});

export const saveOrderSuccess = (order: Order) => ({
    type: actionTypes.SAVE_ORDER_SUCCESS,
    payload: order
});

export const saveOrderFail = (error: any) => ({
    type: actionTypes.SAVE_ORDER_FAIL,
    payload: error
});

export const saveOrder = (order: Order) => {
    return async (dispatch: Dispatch) => {
        dispatch(saveOrderRequest());
        const id: string = uuidv4();
        try {
            setDoc(doc(db, "orders", id), { ...order, id: id });
            dispatch(saveOrderSuccess({ ...order, id: id }));
        } catch (error) {
            console.error(error);
            dispatch(saveOrderFail(error));
        }
    };
};

export const resetSuccessRequest = () => ({
    type: actionTypes.RESET_SUCCESS_MODAL
});

export const resetSuccessModal = () => {
    return (dispatch: Dispatch) => {
        dispatch(resetSuccessRequest());
    };
};

export const fetchOrdersRequest = () => ({
    type: actionTypes.FETCH_ORDERS_REQUEST
});

export const fetchOrdersSuccess = (data: Order[]) => ({
    type: actionTypes.FETCH_ORDERS_SUCCESS,
    payload: data
});

export const fetchOrdersFail = (error: any) => ({
    type: actionTypes.FETCH_ORDERS_FAIL,
    payload: error
});

export const setOrdersUnsubscribe = (unsubscribe: any) => {
    return {
        type: actionTypes.SET_ORDERS_UNSUBSCRIBE,
        payload: unsubscribe
    };
};

export const resetOrdersUnsubscribe = () => {
    return {
        type: actionTypes.RESET_ORDERS_UNSUBSCRIBE
    };
};

export const fetchOrders = () => {
    return (dispatch: Dispatch) => {
        dispatch(fetchOrdersRequest());
        try {
            const today: string = moment().format("DD/MM/YYYY");
            const ordersQuery: Query<DocumentData> = query(collection(db, "orders"), where("deliveryDate", "==", today), orderBy("deliveryTime", "asc"));
            const unsubscribe = onSnapshot(ordersQuery, (snapshot) => {
                const orders: Order[] = snapshot.docs.map(doc => ({ ...doc.data() as Order }));
                dispatch(fetchOrdersSuccess(orders));
            });

            //Dispatch the unsubscribe function to the Redux store
            dispatch(setOrdersUnsubscribe(unsubscribe));
        } catch (error) {
            console.error(error);
            dispatch(fetchOrdersFail(error));
        }
    };
};

export const updateToFinishedOrderRequest = () => ({
    type: actionTypes.UPDATE_TO_FINISHED_ORDER_REQUEST
});

export const updateToFinishedOrderSuccess = () => ({
    type: actionTypes.UPDATE_TO_FINISHED_ORDER_SUCCESS
});

export const updateToFinishedOrderFail = (error: any) => ({
    type: actionTypes.UPDATE_TO_FINISHED_ORDER_FAIL,
    payload: error
});

export const updateToFinishedOrder = (orderId: string) => {
    return async (dispatch: Dispatch) => {
        dispatch(updateToFinishedOrderRequest());
        try {
            const ordersRef: DocumentReference<DocumentData> = doc(db, "orders", orderId);
            await updateDoc(ordersRef, {
                orderStatus: "finished"
            });

            dispatch(updateToFinishedOrderSuccess());
        } catch (error) {
            console.error(error);
            dispatch(updateToFinishedOrderFail(error));
        }
    };
};

export const fetchUserOrdersRequest = () => ({
    type: actionTypes.FETCH_USER_ORDERS_REQUEST
});

export const fetchUserOrdersSuccess = (data: Order[]) => ({
    type: actionTypes.FETCH_USER_ORDERS_SUCCESS,
    payload: data
});

export const fetchUserOrdersFail = (error: any) => ({
    type: actionTypes.FETCH_USER_ORDERS_FAIL,
    payload: error
});

export const fetchUserOrders = (date: string, userId?: string) => {
    return async (dispatch: Dispatch) => {
        dispatch(fetchUserOrdersRequest());
        try {
            const ordersQuery: Query<DocumentData> = query(collection(db, "orders"), where("deliveryDate", "==", date), where("createdUserId", "==", userId));
            const querySnapshot: QuerySnapshot<DocumentData> = await getDocs(ordersQuery);
            const orders: Order[] = querySnapshot.docs.map(doc => ({ ...doc.data() as Order }));
            dispatch(fetchUserOrdersSuccess(orders));
        } catch (error) {
            console.error(error);
            dispatch(fetchUserOrdersFail(error));
        }
    };
};

export const updateMealToSoldoutRequest = () => ({
    type: actionTypes.UPDATE_MEAL_TO_SOLDOUT_REQUEST
});

export const updateMealToSoldoutSuccess = () => ({
    type: actionTypes.UPDATE_MEAL_TO_SOLDOUT_SUCCESS
});

export const updateMealToSoldoutFail = (error: any) => ({
    type: actionTypes.UPDATE_MEAL_TO_SOLDOUT_FAIL,
    payload: error
});

export const updateMealToSoldout = (menuId: string, meals: Meal[]) => {
    return async (dispatch: Dispatch) => {
        dispatch(updateMealToSoldoutRequest());
        try {
            const menuRef: DocumentReference<DocumentData> = doc(db, "menu", menuId);
            await updateDoc(menuRef, {
                meals: meals
            });

            dispatch(updateMealToSoldoutSuccess());
        } catch (error) {
            console.error(error);
            dispatch(updateMealToSoldoutFail(error));
        }
    };
};

export const fetchIncomingOrdersRequest = () => ({
    type: actionTypes.FETCH_INCOMING_ORDERS_REQUEST
});

export const fetchIncomingOrdersSuccess = (data: Order[]) => ({
    type: actionTypes.FETCH_INCOMING_ORDERS_SUCCESS,
    payload: data
});

export const fetchIncomingOrdersFail = (error: any) => ({
    type: actionTypes.FETCH_INCOMING_ORDERS_FAIL,
    payload: error
});

export const setIncomingOrdersUnsubscribe = (unsubscribe: any) => {
    return {
        type: actionTypes.SET_INCOMING_ORDERS_UNSUBSCRIBE,
        payload: unsubscribe
    };
};

export const resetIncomingOrdersUnsubscribe = () => {
    return {
        type: actionTypes.RESET_INCOMING_ORDERS_UNSUBSCRIBE
    };
};

export const fetchIncomingOrders = () => {
    return (dispatch: Dispatch) => {
        dispatch(fetchIncomingOrdersRequest());
        try {
            let nextWorkingDay: number = 1;
            const day: number = moment().day();
            if (day === 5) {
                nextWorkingDay = 3;
            } else if (day === 6) {
                nextWorkingDay = 2;
            }

            const nextDay: string = moment().add(nextWorkingDay, "d").format("DD/MM/YYYY");
            const ordersQuery: Query<DocumentData> = query(collection(db, "orders"), where("deliveryDate", "==", nextDay), orderBy("deliveryTime", "asc"));
            const unsubscribe = onSnapshot(ordersQuery, (snapshot) => {
                const orders: Order[] = snapshot.docs.map(doc => ({ ...doc.data() as Order }));

                dispatch(fetchIncomingOrdersSuccess(orders));
            });

            //Dispatch the unsubscribe function to the Redux store
            dispatch(setIncomingOrdersUnsubscribe(unsubscribe));
        } catch (error) {
            console.error(error);
            dispatch(fetchIncomingOrdersFail(error));
        }
    };
};

export const fetchOatmealsRequest = () => ({
    type: actionTypes.FETCH_OATMEALS_REQUEST
});

export const fetchOatmealsRequestSuccess = (data: Meal[]) => ({
    type: actionTypes.FETCH_OATMEALS_SUCCESS,
    payload: data
});

export const fetchOatmealsRequestFail = (error: any) => ({
    type: actionTypes.FETCH_OATMEALS_FAIL,
    payload: error
});

export const fetchOatmeals = () => {
    return async (dispatch: Dispatch) => {
        dispatch(fetchOatmealsRequest());
        try {
            const oatmealsQuery: Query<DocumentData> = query(collection(db, "meals"), where("meal", "==", "oatmeal"), orderBy("position", "asc"));
            const querySnapshot: QuerySnapshot<DocumentData> = await getDocs(oatmealsQuery);
            const oatmeals: Meal[] = querySnapshot.docs.map(doc => ({ ...doc.data() as Meal }));
            dispatch(fetchOatmealsRequestSuccess(oatmeals));
        } catch (error) {
            console.error(error);
            dispatch(fetchOatmealsRequestFail(error));
        }
    };
};

export const deleteUserOrderRequest = () => ({
    type: actionTypes.DELETE_USER_ORDER_REQUEST
});

export const deleteUserOrderSuccess = () => ({
    type: actionTypes.DELETE_USER_ORDER_SUCCESS,
});

export const deleteUserOrderFail = (error: any) => ({
    type: actionTypes.DELETE_USER_ORDER_FAIL,
    payload: error
});

export const deleteUserOrder = (date: string, userId: string, orderId: string) => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        dispatch(deleteUserOrderRequest());
        try {
            await deleteDoc(doc(db, "orders", orderId));
            dispatch(deleteUserOrderSuccess());
            await dispatch(fetchUserOrders(date, userId));
            toast.success("Usprešno ste obrisali porudžbinu.");
        } catch (error) {
            console.error(error);
            dispatch(deleteUserOrderFail(error));
        }
    };
};

export const fetchDisabledOatmealDatesRequest = () => ({
    type: actionTypes.FETCH_DISABLED_OATMEAL_DATES_REQUEST
});

export const fetchDisabledOatmealDatesSuccess = (data: DisabledOatmealDates[]) => ({
    type: actionTypes.FETCH_DISABLED_OATMEAL_DATES_SUCCESS,
    payload: data
});

export const fetchDisabledOatmealDatesFail = (error: any) => ({
    type: actionTypes.FETCH_DISABLED_OATMEAL_DATES_FAIL,
    payload: error
});

export const fetchDisabledOatmealDates = () => {
    return async (dispatch: Dispatch) => {
        dispatch(fetchDisabledOatmealDatesRequest());
        try {
            const oatmealsDatesQuery: Query<DocumentData> = query(collection(db, "disabledOatmealDates"));
            const querySnapshot: QuerySnapshot<DocumentData> = await getDocs(oatmealsDatesQuery);
            const oatmeals: DisabledOatmealDates[] = querySnapshot.docs.map(doc => ({ ...doc.data() as DisabledOatmealDates }));
            dispatch(fetchDisabledOatmealDatesSuccess(oatmeals));
        } catch (error) {
            console.error(error);
            dispatch(fetchDisabledOatmealDatesFail(error));
        }
    };
};