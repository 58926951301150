import { validateEmail } from "../../helpers/validation";

export interface ContactData {
    fullName: string;
    email: string;
    phone: string;
    message: string;
}

export interface ContactDataErrors {
    fullName?: string;
    email?: string;
    phone?: string;
    message?: string;
}

export const validateForm = (contactData: ContactData): { valid: boolean; errors: ContactDataErrors } => {
    const errors: ContactDataErrors = {};

    //Validate full name
    if (!contactData.fullName.trim()) {
        errors.fullName = "Polje je obavezno.";
    }

    //Validate email
    if (!contactData.email.trim()) {
        errors.email = "Polje je obavezno.";
    } else if (!validateEmail(contactData.email)) {
        errors.email = "Pogrešan format email adrese.";
    }

    //Validate message
    if (!contactData.message.trim()) {
        errors.message = "Polje je obavezno.";
    }

    return {
        valid: Object.keys(errors).length === 0,
        errors
    };
};