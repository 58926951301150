import React, { FC } from "react";
import { Button as MuiButton } from "@mui/material";
import { SxProps } from "@mui/system";

interface ButtonProps {
    label?: string;
    startIcon?: React.ReactNode;
    endIcon?: React.ReactNode;
    disabled?: boolean;
    className?: string;
    sx?: SxProps;
    handleClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const Button: FC<ButtonProps> = ({ label, startIcon, endIcon, disabled, className, sx, handleClick }) => {
    return (
        <MuiButton
            startIcon={startIcon}
            endIcon={endIcon}
            disabled={disabled}
            className={className}
            sx={sx}
            onClick={handleClick}
            variant="contained"
        >
            {label}
        </MuiButton>
    );
};

export default Button;