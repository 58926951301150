import React, { useEffect, FC } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "./store/actions/authActions";
import routes from "./config/routes";
import Navigation from "./components/Navigation/Navigation";
import ProtectedRoute from "./ProtectedRoute";

const App: FC = () => {
    const dispatch = useDispatch();
    const { checkAuthState } = bindActionCreators(actions, dispatch);

    useEffect(() => {
        checkAuthState();
    }, [checkAuthState]);

    return (
        <div>
            <BrowserRouter basename={process.env.PUBLIC_URL}>
                <Navigation />
                <Routes>
                    {routes.map((route) => (
                        <Route
                            key={route.path}
                            path={route.path}
                            element={
                                route.protected ? (
                                    <ProtectedRoute>
                                        <route.component />
                                    </ProtectedRoute>
                                ) : (
                                    <route.component />
                                )
                            }
                        />
                    ))}
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;