import React, { FC, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { state } from "../../index";
import * as actions from "../../store/actions/usersActions";
import authState from "../../store/reducers/authState";
import usersState from "../../store/reducers/usersState";
import { Employee } from "../auth/Auth";
import Loader from "../UI/Loader";
import Input from "../UI/Input";
import Button from "../UI/Button";
import TablePaginationActions from "../UI/TablePaginationActions";
import UserModal from "./Modals/UserModal";
import DeleteUserModal from "./Modals/DeleteUserModal";
import Container from "react-bootstrap/Container";
import { IconButton, Divider, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableFooter, TablePagination, Paper } from "@mui/material";
import { Delete, Edit, PersonAdd } from "@mui/icons-material";

const Users: FC = () => {
    const [openUserModal, setOpenUserModal] = useState<boolean>(false);
    const [openEditUserModal, setOpenEditUserModal] = useState<boolean>(false);
    const [openDeleteUserModal, setOpenDeleteUserModal] = useState<boolean>(false);
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);
    const [selectedUser, setSelectedUser] = useState<Employee>({
        employeeId: "",
        customerId: "",
        email: "",
        fullName: "",
        role: ""
    });

    const dispatch = useDispatch();
    const { fetchEmployees } = bindActionCreators(actions, dispatch);

    const authState: authState = useSelector((state: state) => state.auth);
    const usersState: usersState = useSelector((state: state) => state.users);
    const users: Employee[] = usersState.employees;

    useEffect(() => {
        fetchEmployees(authState.user.customerId);
    }, []);

    const handleChangePage = (e: React.MouseEvent<HTMLButtonElement> | null, newPage: number): void => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setRowsPerPage(parseInt(e.target.value, 10));
        setPage(0);
    };

    const handleOpenEditUserModal = (user: Employee): void => {
        setOpenEditUserModal(true);
        setSelectedUser(user);
    };

    const handleOpenDeleteUserModal = (user: Employee): void => {
        setOpenDeleteUserModal(true);
        setSelectedUser(user);
    };

    const filteredUsers: Employee[] = users.filter(user =>
        user.fullName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.email.toLowerCase().includes(searchTerm.toLowerCase())
    );

    if (usersState.loading) return <Loader />;

    return (
        <Container style={{ marginTop: 40 }}>
            <Divider sx={{ mb: 1 }}>
                <h3 className="main_title">Korisnici</h3>
            </Divider>
            <div className="search_input_container">
                <Input
                    label="Pretraga"
                    type="text"
                    value={searchTerm}
                    onChange={(e) => { setSearchTerm(e.target.value) }}
                />
                <Button
                    label="Dodaj korisnika"
                    startIcon={<PersonAdd />}
                    className="btn_success"
                    handleClick={() => setOpenUserModal(true)}
                />
            </div>
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow sx={{ backgroundColor: "#E6B238" }}>
                            <TableCell sx={{ color: "#fff" }}>Ime i prezime</TableCell>
                            <TableCell sx={{ color: "#fff" }}>Email</TableCell>
                            <TableCell sx={{ color: "#fff" }}></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(rowsPerPage > 0
                            ? filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            : filteredUsers
                        ).map(user => (
                            <TableRow
                                key={user.employeeId}
                                sx={{
                                    "&:nth-of-type(odd)": {
                                        backgroundColor: "rgba(0,0,0,0.04)"
                                    },
                                    "&:last-child td, &:last-child th": { border: 0 }
                                }}
                            >
                                <TableCell component="th" scope="row">
                                    {user.fullName}
                                </TableCell>
                                <TableCell>{user.email}</TableCell>
                                <TableCell>
                                    <IconButton
                                        sx={{ ml: 1 }}
                                        size="small"
                                        onClick={() => handleOpenEditUserModal(user)}
                                    >
                                        <Edit />
                                    </IconButton>
                                    <IconButton
                                        sx={{ ml: 1 }}
                                        size="small"
                                        className="btn_delete"
                                        onClick={() => handleOpenDeleteUserModal(user)}
                                    >
                                        <Delete />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                labelRowsPerPage="Redova po stranici"
                                count={filteredUsers.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                colSpan={3}
                                rowsPerPageOptions={[5, 10, 25, { label: "Svi", value: -1 }]}
                                labelDisplayedRows={({ from, to, count }) =>
                                    `${from}–${to} od ${count !== -1 ? count : `više od ${to}`}`
                                }
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>

            {openUserModal &&
                <UserModal
                    open={openUserModal}
                    isAddMode
                    selectedUser={selectedUser}
                    onHandleClose={() => setOpenUserModal(false)}
                />}
            {openEditUserModal &&
                <UserModal
                    open={openEditUserModal}
                    isAddMode={false}
                    selectedUser={selectedUser}
                    onHandleClose={() => setOpenEditUserModal(false)}
                />}
            <DeleteUserModal
                open={openDeleteUserModal}
                selectedUser={selectedUser}
                onHandleClose={() => setOpenDeleteUserModal(false)}
            />
        </Container>
    );
};

export default Users;