import { NavigateFunction } from "react-router-dom";
import { AnyAction, Dispatch } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { auth, db } from "../../config/firebase";
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut } from "firebase/auth";
import { collection, getDocs, query, where, setDoc, doc, Query, DocumentData, QuerySnapshot } from "firebase/firestore";
import * as actionTypes from "../types/authActionTypes";
import { User } from "../reducers/authState";
import { Customer, Employee } from "../../components/auth/Auth";
import { toast } from "react-toastify";

export const registerUserRequest = () => ({
    type: actionTypes.REGISTER_USER_REQUEST,
});

export const registerUserSuccess = (uid: string) => ({
    type: actionTypes.REGISTER_USER_SUCCESS,
    payload: uid
});

export const registerUserFail = (error: any) => ({
    type: actionTypes.REGISTER_USER_FAIL,
    payload: error
});

export const registerUser = (customerId: string, email: string, password: string, navigate: NavigateFunction) => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        dispatch(registerUserRequest());
        try {
            const employeeData: Employee = await checkIfUserBelongsToCustomer(email, customerId);
            if (employeeData) {
                //if the user belongs to customer, create new
                const response = await createUserWithEmailAndPassword(auth, email, password);
                dispatch(logoutUser());
                const userUid: string = response.user.uid;
                dispatch(registerUserSuccess(userUid));

                //get customer data 
                const customerData: Customer = await getCustomerInfo(customerId);

                await setDoc(doc(db, "users", userUid), {
                    address: customerData.address,
                    canOrderCurrentDay: customerData.canOrderCurrentDay,
                    customerId: customerId,
                    customerName: customerData.name,
                    customerSubvention: customerData.subvention,
                    deliveryTime: customerData.deliveryTime,
                    email: email,
                    fullName: employeeData.fullName,
                    id: userUid,
                    isActive: true,
                    phoneNumber: customerData.phoneNumber,
                    role: employeeData.role,
                    userType: "legal",
                });

                toast.success("Usprešno ste se registrovali.");
                navigate("/login");
            } else {
                toast.error("Unešena email adresa ne pripada odabranoj kompaniji.");
                dispatch(registerUserFail("The entered email doesn't belong to the selected company."));
            }
        } catch (error) {
            toast.error("Korisnik sa unetom email adresom već postoji.");
            dispatch(registerUserFail(error));
        }
    };
};

const checkIfUserBelongsToCustomer = async (email: string, customerId: string): Promise<Employee> => {
    const usersQuery: Query<DocumentData> = query(collection(db, "customers", customerId, "employees"), where("email", "==", email));
    const querySnapshot: QuerySnapshot<DocumentData> = await getDocs(usersQuery);
    const employeeData: Employee = querySnapshot.docs.map(doc => ({ ...doc.data() as Employee }))[0];

    return employeeData;
}

const getCustomerInfo = async (customerId: string): Promise<Customer> => {
    const customersQuery: Query<DocumentData> = query(collection(db, "customers"), where("id", "==", customerId));
    const querySnapshot: QuerySnapshot<DocumentData> = await getDocs(customersQuery);
    const customerData: Customer = querySnapshot.docs.map(doc => ({ ...doc.data() as Customer }))[0];

    return customerData;
}

export const loginUserRequest = () => ({
    type: actionTypes.LOGIN_USER_REQUEST,
});

export const loginUserSuccess = (token: string, uid: string, userData: User) => ({
    type: actionTypes.LOGIN_USER_SUCCESS,
    payload: { token, uid, userData }
});

export const loginUserFail = (error: any) => ({
    type: actionTypes.LOGIN_USER_FAIL,
    payload: error
});

export const loginUser = (email: string, password: string) => {
    return async (dispatch: Dispatch) => {
        dispatch(loginUserRequest());
        try {
            const response = await signInWithEmailAndPassword(auth, email, password);
            const token: string = await response.user.getIdToken();
            const userUid: string = response.user.uid;
            const userData = await getUserInfo(userUid);

            dispatch(loginUserSuccess(token, userUid, userData));
        } catch (error) {
            dispatch(loginUserFail(error));
            toast.error("Pogrešan email ili lozinka. Pokušajte ponovo.");
        }
    };
};

const getUserInfo = async (uid: string): Promise<User> => {
    const usersQuery: Query<DocumentData> = query(collection(db, "users"), where("id", "==", uid));
    const querySnapshot: QuerySnapshot<DocumentData> = await getDocs(usersQuery);
    const userData: User = querySnapshot.docs.map(doc => ({ ...doc.data() as User }))[0];

    return userData;
}

export const checkAuthStateRequest = () => ({
    type: actionTypes.CHECK_AUTH_STATE_REQUEST,
});

export const checkAuthStateSuccess = (token: string, uid: string, userData: User) => ({
    type: actionTypes.CHECK_AUTH_STATE_SUCCESS,
    payload: { token, uid, userData }
});

export const checkAuthStateFail = () => ({
    type: actionTypes.CHECK_AUTH_STATE_FAIL
});

export const checkAuthState = () => {
    return (dispatch: Dispatch) => {
        dispatch(checkAuthStateRequest());
        auth.onAuthStateChanged(async (user) => {
            if (user) {
                const token: string = await user.getIdToken();
                const userData = await getUserInfo(user.uid);
                if (userData) {
                    dispatch(checkAuthStateSuccess(token, user.uid, userData));
                } else {
                    dispatch(checkAuthStateFail());
                }
            } else {
                dispatch(checkAuthStateFail());
            }
        });
    };
};

export const logoutUserRequest = () => ({
    type: actionTypes.LOGOUT_USER_REQUEST,
});

export const logoutUserSuccess = () => ({
    type: actionTypes.LOGOUT_USER_SUCCESS,
});

export const logoutUserFail = (error: any) => ({
    type: actionTypes.LOGOUT_USER_FAIL,
    payload: error
});

export const logoutUser = () => {
    return async (dispatch: Dispatch) => {
        dispatch(logoutUserRequest());
        try {
            await signOut(auth);
            dispatch(logoutUserSuccess());
        } catch (error) {
            dispatch(logoutUserFail(error));
        }
    };
};

export const fetchCustomersRequest = () => ({
    type: actionTypes.FETCH_CUSTOMERS_REQUEST,
});

export const fetchCustomersSuccess = (data: Customer[]) => ({
    type: actionTypes.FETCH_CUSTOMERS_SUCCESS,
    payload: data
});

export const fetchCustomersFail = (error: any) => ({
    type: actionTypes.FETCH_CUSTOMERS_FAIL,
    payload: error
});

export const fetchCustomers = () => {
    return async (dispatch: Dispatch) => {
        dispatch(fetchCustomersRequest());
        try {
            const querySnapshot: QuerySnapshot<DocumentData> = await getDocs(collection(db, "customers"));
            const customers: Customer[] = querySnapshot.docs.map(doc => ({ ...doc.data() as Customer }));
            dispatch(fetchCustomersSuccess(customers));
        } catch (error) {
            console.error(error);
            dispatch(fetchCustomersFail(error));
        }
    };
};