import React, { FC } from "react";
import { Meal } from "../../store/reducers/menuState";
import { portionLabels } from "../Menu/Menu";
import { Select as MuiSelect, SelectChangeEvent, MenuItem, FormControl, InputLabel } from "@mui/material";

interface SelectProps {
    label: string;
    value: string | any;
    meals: Meal[];
    name: string;
    multiple: boolean;
    onSelectChange: (e: SelectChangeEvent) => void;
}

const GroupingMealsSelect: FC<SelectProps> = ({ label, value, meals, name, multiple, onSelectChange }: SelectProps) => {
    const menuItems: React.ReactNode[] = meals?.reduce((acc: React.ReactNode[], meal) => {
        meal.portions.forEach(portion => {
            const portionLabel: string = portion.size !== "regular" ? `${meal.name} - ${portionLabels[portion.size]}` : meal.name;
            acc.push(
                <MenuItem key={portion.id} value={portion.id}>
                    {portionLabel}
                </MenuItem>
            );
        });
        return acc;
    }, []);

    return (
        <FormControl fullWidth sx={{ my: 1.5 }}>
            <InputLabel
                sx={{
                    "&.Mui-focused": {
                        color: "#6c757d"
                    },
                    "& .MuiFormLabel-asterisk": {
                        color: "#ff0000"
                    }
                }}
            >
                {label}
            </InputLabel>
            <MuiSelect
                label={label}
                value={value}
                defaultValue=""
                name={name}
                multiple={multiple}
                onChange={onSelectChange}
                size="medium"
                sx={{
                    ".MuiSelect-select": {
                        textAlign: "left",
                    },
                    "&.MuiOutlinedInput-root": {
                        background: "#fff",
                        "&:hover fieldset": {
                            border: "1px solid #6c757d"
                        },
                        "&.Mui-focused fieldset": {
                            border: "1px solid #6c757d"
                        }
                    }
                }}
            >
                <MenuItem value="">Odaberi</MenuItem>
                {menuItems}
            </MuiSelect>
        </FormControl>
    );
};

export default GroupingMealsSelect;