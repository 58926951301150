import React, { FC } from "react";
import { Select as MuiSelect, SelectChangeEvent, MenuItem, FormControl, InputLabel } from "@mui/material";

interface SelectOption {
    key: string;
    value: string;
}

interface SelectProps {
    label?: string;
    value: string | any;
    options: SelectOption[];
    disabled?: boolean;
    name?: string;
    required?: boolean;
    error?: string;
    onSelectChange: (e: SelectChangeEvent) => void;
}

const Select: FC<SelectProps> = ({ label, value, options, disabled = false, name, required = false, error, onSelectChange }: SelectProps) => {
    return (
        <FormControl fullWidth sx={{ my: 1.5 }}>
            <InputLabel
                required={required}
                sx={{
                    "&.Mui-focused": {
                        color: "#6c757d"
                    },
                    "& .MuiFormLabel-asterisk": {
                        color: "#ff0000"
                    }
                }}
            >
                {label}
            </InputLabel>
            <MuiSelect
                label={label}
                value={value}
                defaultValue=""
                disabled={disabled}
                name={name}
                onChange={onSelectChange}
                size="medium"
                sx={{
                    ".MuiSelect-select": {
                        textAlign: "left",
                    },
                    "&.MuiOutlinedInput-root": {
                        background: "#fff",
                        "&:hover fieldset": {
                            border: "1px solid #6c757d"
                        },
                        "&.Mui-focused fieldset": {
                            border: "1px solid #6c757d"
                        }
                    }
                }}
            >
                <MenuItem value="">Odaberi</MenuItem>
                {options.map(option => (
                    <MenuItem key={option.key} value={option.key}>
                        {option.value}
                    </MenuItem>
                ))}
            </MuiSelect>
            {error && <div className="error_message">{error}</div>}
        </FormControl>
    );
};

export default Select;