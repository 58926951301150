import React, { FC } from "react";
import { Box } from "@mui/material";

interface TabPanelProps {
    index: number;
    value: number;
    children?: React.ReactNode;
}

const TabPanel: FC<TabPanelProps> = ({ index, value, children }) => {
    const isVisible = value === index;

    return (
        <div
            role="tabpanel"
            hidden={!isVisible}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
        >
            {isVisible &&
                <Box>
                    {children}
                </Box>
            }
        </div>
    );
}

export default TabPanel;