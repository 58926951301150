import { Order } from "../../components/Menu/Menu";
import { Menu, Meal, DisabledOatmealDates } from "../reducers/menuState";

export const FETCH_MENU_REQUEST = "FETCH_MENU_REQUEST";
export const FETCH_MENU_SUCCESS = "FETCH_MENU_SUCCESS";
export const FETCH_MENU_FAIL = "FETCH_MENU_FAIL";

export const SAVE_ORDER_REQUEST = "SAVE_ORDER_REQUEST";
export const SAVE_ORDER_SUCCESS = "SAVE_ORDER_SUCCESS";
export const SAVE_ORDER_FAIL = "SAVE_ORDER_FAIL";

export const RESET_SUCCESS_MODAL = "RESET_SUCCESS_MODAL";

export const FETCH_ORDERS_REQUEST = "FETCH_ORDERS_REQUEST";
export const FETCH_ORDERS_SUCCESS = "FETCH_ORDERS_SUCCESS";
export const FETCH_ORDERS_FAIL = "FETCH_ORDERS_FAIL";

export const SET_ORDERS_UNSUBSCRIBE = "SET_ORDERS_UNSUBSCRIBE";
export const RESET_ORDERS_UNSUBSCRIBE = "RESET_ORDERS_UNSUBSCRIBE";

export const UPDATE_TO_FINISHED_ORDER_REQUEST = "UPDATE_TO_FINISHED_ORDER_REQUEST";
export const UPDATE_TO_FINISHED_ORDER_SUCCESS = "UPDATE_TO_FINISHED_ORDER_SUCCESS";
export const UPDATE_TO_FINISHED_ORDER_FAIL = "UPDATE_TO_FINISHED_ORDER_FAIL";

export const FETCH_USER_ORDERS_REQUEST = "FETCH_USER_ORDERS_REQUEST";
export const FETCH_USER_ORDERS_SUCCESS = "FETCH_USER_ORDERS_SUCCESS";
export const FETCH_USER_ORDERS_FAIL = "FETCH_USER_ORDERS_FAIL";

export const UPDATE_MEAL_TO_SOLDOUT_REQUEST = "UPDATE_MEAL_TO_SOLDOUT_REQUEST";
export const UPDATE_MEAL_TO_SOLDOUT_SUCCESS = "UPDATE_MEAL_TO_SOLDOUT_SUCCESS";
export const UPDATE_MEAL_TO_SOLDOUT_FAIL = "UPDATE_MEAL_TO_SOLDOUT_FAIL";

export const FETCH_INCOMING_ORDERS_REQUEST = "FETCH_INCOMING_ORDERS_REQUEST";
export const FETCH_INCOMING_ORDERS_SUCCESS = "FETCH_INCOMING_ORDERS_SUCCESS";
export const FETCH_INCOMING_ORDERS_FAIL = "FETCH_INCOMING_ORDERS_FAIL";

export const SET_INCOMING_ORDERS_UNSUBSCRIBE = "SET_INCOMING_ORDERS_UNSUBSCRIBE";
export const RESET_INCOMING_ORDERS_UNSUBSCRIBE = "RESET_INCOMING_ORDERS_UNSUBSCRIBE";

export const FETCH_OATMEALS_REQUEST = "FETCH_OATMEALS_REQUEST";
export const FETCH_OATMEALS_SUCCESS = "FETCH_OATMEALS_SUCCESS";
export const FETCH_OATMEALS_FAIL = "FETCH_OATMEALS_FAIL";

export const DELETE_USER_ORDER_REQUEST = "DELETE_USER_ORDER_REQUEST";
export const DELETE_USER_ORDER_SUCCESS = "DELETE_USER_ORDER_SUCCESS";
export const DELETE_USER_ORDER_FAIL = "DELETE_USER_ORDER_FAIL";

export const FETCH_DISABLED_OATMEAL_DATES_REQUEST = "FETCH_DISABLED_OATMEAL_DATES_REQUEST";
export const FETCH_DISABLED_OATMEAL_DATES_SUCCESS = "FETCH_DISABLED_OATMEAL_DATES_SUCCESS";
export const FETCH_DISABLED_OATMEAL_DATES_FAIL = "FETCH_DISABLED_OATMEAL_DATES_FAIL";

export interface FetchMenuRequestAction {
    type: typeof FETCH_MENU_REQUEST;
}

export interface FetchMenuSuccessAction {
    type: typeof FETCH_MENU_SUCCESS;
    payload: Menu;
}

export interface FetchMenuFailAction {
    type: typeof FETCH_MENU_FAIL;
    payload: Error;
}

export interface SaveOrderRequestAction {
    type: typeof SAVE_ORDER_REQUEST;
}

export interface SaveOrderSuccessAction {
    type: typeof SAVE_ORDER_SUCCESS;
    payload: Order;
}

export interface SaveOrderFailAction {
    type: typeof SAVE_ORDER_FAIL;
    payload: Error;
}

export interface ResetSuccessAction {
    type: typeof RESET_SUCCESS_MODAL;
}

export interface FetchOrdersRequestAction {
    type: typeof FETCH_ORDERS_REQUEST;
}

export interface FetchOrdersSuccessAction {
    type: typeof FETCH_ORDERS_SUCCESS;
    payload: Order[];
}

export interface FetchOrdersFailAction {
    type: typeof FETCH_ORDERS_FAIL;
    payload: Error;
}

export interface SetOrdersUnsubscribeAction {
    type: typeof SET_ORDERS_UNSUBSCRIBE;
    payload: any;
}

export interface ResetOrdersUnsubscribeAction {
    type: typeof RESET_ORDERS_UNSUBSCRIBE;
}

export interface UpdateToFinishedOrderRequestAction {
    type: typeof UPDATE_TO_FINISHED_ORDER_REQUEST;
}

export interface UpdateToFinishedOrderSuccessAction {
    type: typeof UPDATE_TO_FINISHED_ORDER_SUCCESS;
}

export interface UpdateToFinishedOrderFailAction {
    type: typeof UPDATE_TO_FINISHED_ORDER_FAIL;
    payload: Error;
}

export interface FetchUserOrdersRequestAction {
    type: typeof FETCH_USER_ORDERS_REQUEST;
}

export interface FetchUserOrdersSuccessAction {
    type: typeof FETCH_USER_ORDERS_SUCCESS;
    payload: Order[];
}

export interface FetchUserOrdersFailAction {
    type: typeof FETCH_USER_ORDERS_FAIL;
    payload: Error;
}

export interface UpdateMealToSoldoutRequestAction {
    type: typeof UPDATE_MEAL_TO_SOLDOUT_REQUEST;
}

export interface UpdateMealToSoldoutSuccessAction {
    type: typeof UPDATE_MEAL_TO_SOLDOUT_SUCCESS;
}

export interface UpdateMealToSoldoutFailAction {
    type: typeof UPDATE_MEAL_TO_SOLDOUT_FAIL;
    payload: Error;
}

export interface FetchIncomingOrdersRequestAction {
    type: typeof FETCH_INCOMING_ORDERS_REQUEST;
}

export interface FetchIncomingOrdersSuccessAction {
    type: typeof FETCH_INCOMING_ORDERS_SUCCESS;
    payload: Order[];
}

export interface FetchIncomingOrdersFailAction {
    type: typeof FETCH_INCOMING_ORDERS_FAIL;
    payload: Error;
}

export interface SetIncomingOrdersUnsubscribeAction {
    type: typeof SET_INCOMING_ORDERS_UNSUBSCRIBE;
    payload: any;
}

export interface ResetIncomingOrdersUnsubscribeAction {
    type: typeof RESET_INCOMING_ORDERS_UNSUBSCRIBE;
}

export interface FetchOatmealsRequestAction {
    type: typeof FETCH_OATMEALS_REQUEST;
}

export interface FetchOatmealsSuccessAction {
    type: typeof FETCH_OATMEALS_SUCCESS;
    payload: Meal[];
}

export interface FetchOatmealsFailAction {
    type: typeof FETCH_OATMEALS_FAIL;
    payload: Error;
}

export interface DeleteUserOrderRequestAction {
    type: typeof DELETE_USER_ORDER_REQUEST;
}

export interface DeleteUserOrderSuccessAction {
    type: typeof DELETE_USER_ORDER_SUCCESS;
}

export interface DeleteUserOrderFailAction {
    type: typeof DELETE_USER_ORDER_FAIL;
    payload: Error;
}

export interface FetchDisabledOatmealDatesAction {
    type: typeof FETCH_DISABLED_OATMEAL_DATES_REQUEST;
}

export interface FetchDisabledOatmealDatesSuccessAction {
    type: typeof FETCH_DISABLED_OATMEAL_DATES_SUCCESS;
    payload: DisabledOatmealDates[];
}

export interface FetchDisabledOatmealDatesFailAction {
    type: typeof FETCH_DISABLED_OATMEAL_DATES_FAIL;
    payload: Error;
}

export type ActionTypes =
    | FetchMenuRequestAction
    | FetchMenuSuccessAction
    | FetchMenuFailAction
    | SaveOrderRequestAction
    | SaveOrderSuccessAction
    | SaveOrderFailAction
    | ResetSuccessAction
    | FetchOrdersRequestAction
    | FetchOrdersSuccessAction
    | FetchOrdersFailAction
    | SetOrdersUnsubscribeAction
    | ResetOrdersUnsubscribeAction
    | UpdateToFinishedOrderRequestAction
    | UpdateToFinishedOrderSuccessAction
    | UpdateToFinishedOrderFailAction
    | FetchUserOrdersRequestAction
    | FetchUserOrdersSuccessAction
    | FetchUserOrdersFailAction
    | UpdateMealToSoldoutRequestAction
    | UpdateMealToSoldoutSuccessAction
    | UpdateMealToSoldoutFailAction
    | FetchIncomingOrdersRequestAction
    | FetchIncomingOrdersSuccessAction
    | FetchIncomingOrdersFailAction
    | SetIncomingOrdersUnsubscribeAction
    | ResetIncomingOrdersUnsubscribeAction
    | FetchOatmealsRequestAction
    | FetchOatmealsSuccessAction
    | FetchOatmealsFailAction
    | DeleteUserOrderRequestAction
    | DeleteUserOrderSuccessAction
    | DeleteUserOrderFailAction
    | FetchDisabledOatmealDatesAction
    | FetchDisabledOatmealDatesSuccessAction
    | FetchDisabledOatmealDatesFailAction;