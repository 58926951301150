import React, { FC, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { state } from "../../../index";
import * as actions from "../../../store/actions/authActions";
import authState from "../../../store/reducers/authState";
import { Customer, RegisterData, RegisterDataErrors } from "../Auth";
import { validateForm } from "./validationUtils";
import Input from "../../UI/Input";
import PasswordInput from "../../UI/PasswordInput";
import Button from "../../UI/Button";
import Select from "../../UI/Select";
import TermsOfUseCheckbox from "./TermsOfUseCheckbox";
import Container from "react-bootstrap/Container";
import { FormControl, SelectChangeEvent } from "@mui/material";

const Register: FC = () => {
    const [registerData, setRegisterData] = useState<RegisterData>({
        email: "",
        customer: "",
        password: "",
        repeatPassword: "",
        termsOfUse: false
    });
    const [errors, setErrors] = useState<RegisterDataErrors>({
        email: "",
        customer: "",
        password: "",
        repeatPassword: "",
        termsOfUse: ""
    });

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { fetchCustomers, registerUser } = bindActionCreators(actions, dispatch);

    const authState: authState = useSelector((state: state) => state.auth);
    const customers: Customer[] = authState.customers;

    useEffect(() => {
        fetchCustomers();
    }, []);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const { name, value } = e.target;
        setRegisterData({ ...registerData, [name]: value });
        setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    };

    const handleSelectChange = (e: SelectChangeEvent): void => {
        const { name, value } = e.target;
        setRegisterData({ ...registerData, [name]: value });
        setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    };

    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const { checked } = e.target;
        setRegisterData({ ...registerData, termsOfUse: checked });
        setErrors((prevErrors) => ({ ...prevErrors, termsOfUse: "" }));
    };

    const handleCreateAccount = (e: React.FormEvent): void => {
        e.preventDefault();
        let { valid, errors } = validateForm(registerData);
        if (valid) {
            registerUser(registerData.customer, registerData.email, registerData.password, navigate);
        } else {
            setErrors(errors);
        }
    };

    return (
        <Container>
            <div className="auth_wraper">
                <div className="auth_box">
                    <div className="auth_header">
                        <h2>Registrujte se</h2>
                    </div>
                    <div className="auth_form">
                        <Input
                            label="Email"
                            type="text"
                            value={registerData.email}
                            name="email"
                            required
                            error={errors.email}
                            onChange={handleInputChange}
                        />
                        <Select
                            label="Kompanija"
                            value={registerData.customer}
                            options={customers.map(customer => ({ key: customer.id, value: customer.name }))}
                            name="customer"
                            required
                            error={errors.customer}
                            onSelectChange={handleSelectChange}
                        />
                        <PasswordInput
                            label="Lozinka"
                            value={registerData.password}
                            name="password"
                            required
                            error={errors.password}
                            onChange={handleInputChange}
                        />
                        <PasswordInput
                            label="Potvrdite lozinku"
                            value={registerData.repeatPassword}
                            name="repeatPassword"
                            required
                            error={errors.repeatPassword}
                            onChange={handleInputChange}
                        />
                        <TermsOfUseCheckbox
                            checked={registerData.termsOfUse}
                            error={errors.termsOfUse}
                            handleChange={handleCheckboxChange}
                        />
                        <FormControl sx={{ my: 1.5 }}>
                            <Button
                                label="Registrujte se"
                                className="btn_success"
                                handleClick={handleCreateAccount}
                            />
                        </FormControl>
                        <FormControl sx={{ display: "flex", mb: 2 }}>
                            <Link to="/login" className="login_register_link">
                                Već imate nalog? Prijavite se.
                            </Link>
                        </FormControl>
                    </div>
                </div>
            </div>
        </Container >
    );
};

export default Register;