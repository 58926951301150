import { Moment } from "moment";
import { Order, portionLabels, SelectedMeal } from "../components/Menu/Menu";
import { GroupMeals } from "../components/Orders/Orders";
import { Meal } from "../store/reducers/menuState";

export const calculateTotalOrderAmount = (orders: Order[]): number => {
    return orders.reduce((acc, order) => {
        const mealSum: number = order.meals.reduce((mealAcc, meal) => {
            return mealAcc + (meal.amount * meal.price);
        }, 0);
        return acc + mealSum;
    }, 0);
};

export const calculateTotalCustomerSubvention = (orders: Order[]): number => {
    return orders.reduce((acc, order) => {
        return acc + order.customerSubvention;
    }, 0);
};

export const calculateTotalMealsSum = (groupedMeals: SelectedMeal[][]): number => {
    return groupedMeals.reduce((totalSum, mealGroup) => {
        return totalSum + mealGroup.reduce((total, meal) => total + meal.amount, 0);
    }, 0);
};

export const calculateUserSubvention = (customerSubvention: number, userOrders: Order[], totalAmount: number): number => {
    let subvention: number = 0;
    const userTotalAmount: number = calculateTotalOrderAmount(userOrders);

    if (customerSubvention > 0) {
        if (userTotalAmount >= customerSubvention) {
            subvention = 0;
        } else if ((userTotalAmount === 0 && totalAmount < customerSubvention) || (customerSubvention > userTotalAmount && (customerSubvention - userTotalAmount) > totalAmount)) {
            subvention = totalAmount;
        } else if (customerSubvention > userTotalAmount && (customerSubvention - userTotalAmount) <= totalAmount) {
            subvention = customerSubvention - userTotalAmount;
        } else {
            subvention = customerSubvention;
        }
    }

    return subvention;
}

export const groupMealsByMealId = (orders: Order[]): SelectedMeal[][] => {
    const flatMeals: SelectedMeal[] = orders.flatMap(order => order.meals);
    const sortedMeals: SelectedMeal[] = flatMeals.sort((a, b) => a.mealNumber - b.mealNumber);

    return Object.values(sortedMeals.reduce((acc: GroupMeals, meal: SelectedMeal) => {
        if (!acc[meal.id]) acc[meal.id] = [];
        acc[meal.id].push(meal);
        return acc;
    }, {}));
};

export const formatCurrency = (amount: number): string => {
    return amount.toLocaleString("sr", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
};

export const generateMenuImage = (workingDays: Moment[], tabValue: number, meals: Meal[]) => {
    const canvas: HTMLCanvasElement = document.createElement("canvas");
    const context: CanvasRenderingContext2D | null = canvas.getContext("2d");

    if (!context) {
        console.error("Unable to get 2D context");
        return;
    }

    //Load the image from the public/logos folder
    const img: HTMLImageElement = new Image();
    img.src = `${process.env.PUBLIC_URL}/logos/logo.png`;

    const separatorImage: HTMLImageElement = new Image();
    separatorImage.src = `${process.env.PUBLIC_URL}/img/meal_separator.png`;

    //Draw the image on the canvas
    separatorImage.onload = () => {
        //Set the canvas dimensions
        canvas.width = 1414;
        canvas.height = 2000;

        //Set the white background
        context.fillStyle = "#fff";
        context.fillRect(0, 0, canvas.width, canvas.height);

        //Draw the title
        context.fillStyle = "#E6B238";
        context.font = "90px 'Arapey', serif";
        const titleText: string = workingDays[tabValue].locale("sr").format("dddd").toUpperCase();
        const titleX: number = (canvas.width - context.measureText(titleText).width) / 2;
        const titleY: number = 120;
        context.fillText(titleText, titleX, titleY);

        //Draw the logo image next to title
        const logoX: number = titleX + context.measureText(titleText).width + 120;
        const logoY: number = 30;
        context.drawImage(img, logoX, logoY, 164, 100);

        context.font = "42px 'Arapey', serif";

        let nameY: number = 200;
        meals.forEach((meal: Meal, i: number) => {
            //Draw meal name
            const mealName: string = `${i + 1}. ${meal.name}`;
            const nameX: number = (canvas.width - context.measureText(mealName).width) / 2;
            context.fillStyle = "#000";
            context.font = "42px 'Arapey', serif";

            if (i >= meals.length - 9) {
                //Last 8 meals
                const price: number = meal.portions?.[0]?.price || 0;
                const nameWithPrice: string = `${mealName} - ${formatCurrency(price)} RSD`;
                const nameX: number = (canvas.width - context.measureText(nameWithPrice).width) / 2;
                context.fillText(nameWithPrice, nameX, nameY);

                nameY += 55;
            } else {
                context.fillText(mealName, nameX, nameY);

                const description: string = meal.description;
                const portions: string = meal.portions.map(portion => `${portionLabels[portion.size]}: ${formatCurrency(portion.price)} RSD`).join(" ");

                //Increase the space between meal name and description, if exists, by 15 pixels
                const spaceBetweenNameAndDescription: number = description.length ? 15 : 0;
                nameY += spaceBetweenNameAndDescription;

                const fontSize: number = 30;
                const availableWidth: number = canvas.width * 0.8;
                //Calculate the starting position for descriptionX to center it horizontally
                const descriptionX: number = (canvas.width - availableWidth) / 2;
                //Use the wrapText function to handle multiline descriptions
                const lines: number = wrapText(context, description, descriptionX, nameY + fontSize, availableWidth, fontSize * 1.3);

                //Draw portions information below the description
                context.font = "42px 'Arapey', serif";
                const portionsWidth: number = context.measureText(portions).width;
                const portionsX: number = (canvas.width - portionsWidth) / 2;
                const portionsY: number = nameY + (lines * fontSize) + spaceBetweenNameAndDescription + 30; // Adjusted vertical position
                context.fillText(portions, portionsX, portionsY);

                //Adjust nameY based on the total height used by the current meal
                const totalHeight: number = (lines * fontSize) + spaceBetweenNameAndDescription + 25;
                nameY += totalHeight;
            }

            //Add a space between meals, except for the last 8 meals
            const lineSpacing: number = (i < meals.length - 9) ? 100 : 0;
            nameY += lineSpacing;

            if (i < meals.length - 9) {
                // Draw the image after each meal, except for the last 8 meals
                const imgX: number = (canvas.width - 998) / 2;
                context.drawImage(separatorImage, imgX, nameY - 90, 998, 60);
            }
        });

        const imgX: number = (canvas.width - 998) / 2;
        context.drawImage(separatorImage, imgX, nameY - 50, 998, 60);

        const footerText: string = "Nema ručka bez domaćeg kuvanog jela!";
        const footerTextX: number = (canvas.width - context.measureText(footerText).width) / 2;
        context.fillStyle = "#E6B238";
        context.fillText(footerText, footerTextX, nameY + 50);

        const footerTextInfo: string = "Tolstojeva 4, Tel: 062/224280";
        const footerTextInfoX: number = (canvas.width - context.measureText(footerTextInfo).width) / 2;
        context.fillText(footerTextInfo, footerTextInfoX, nameY + 110);

        //Set the image source
        const dataUrl: string = canvas.toDataURL("image/png");

        //Trigger download
        const link: HTMLAnchorElement = document.createElement("a");
        link.href = dataUrl;
        link.download = `${workingDays[tabValue].format("DD_MM")}.png`;
        link.click();
    };
};

const wrapText = (context: CanvasRenderingContext2D, text: string, x: number, y: number, maxWidth: number, lineHeight: number) => {
    context.font = "34px 'Arapey', serif";
    const words: string[] = text.split(" ");
    let currentLine: string = "";
    let offsetY: number = 0;
    let lineCount: number = 1;

    for (let i = 0; i < words.length; i++) {
        const potentialLine: string = currentLine + words[i] + " ";
        const potentialLineWidth: number = context.measureText(potentialLine).width;

        if (potentialLineWidth > maxWidth && i > 0) {
            const currentLineWidth: number = context.measureText(currentLine).width;
            const centeredLineX: number = x + (maxWidth - currentLineWidth) / 2; //Center the line horizontally
            context.fillText(currentLine, centeredLineX, y + offsetY);
            currentLine = words[i] + " ";
            offsetY += lineHeight;
            lineCount += 1;
        } else {
            currentLine = potentialLine;
        }
    }

    const currentLineWidth: number = context.measureText(currentLine).width;
    const centeredLineX: number = x + (maxWidth - currentLineWidth) / 2; //Center the last line horizontally
    context.fillText(currentLine, centeredLineX, y + offsetY);

    //Move to the next line
    offsetY += lineHeight;

    //Return the number of lines
    return lineCount > 0 ? lineCount : 1;
}