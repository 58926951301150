import React, { FC, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { state } from "../../../index";
import * as menuActions from "../../../store/actions/menuActions";
import authState from "../../../store/reducers/authState";
import menuState from "../../../store/reducers/menuState";
import { Order, SelectedMeal, portionLabels } from "../../Menu/Menu";
import { calculateTotalOrderAmount, formatCurrency } from "../../../helpers/utils";
import moment, { Moment } from "moment";
import "moment/locale/sr";
import Loader from "../../UI/Loader";
import Button from "../../UI/Button";
import DeleteOrderModal from "./DeleteOrderModal";
import { Box, Divider, Modal, ModalDialog, ModalOverflow, Typography } from "@mui/joy";
import { Delete } from "@mui/icons-material";

interface OrderForCurrentUserModalProps {
    open: boolean;
    selectedDate: Moment;
    onHandleClose: () => void;
}

const OrderForCurrentUserModal: FC<OrderForCurrentUserModalProps> = ({ open, selectedDate, onHandleClose }) => {
    const [openDeleteOrderModal, setOpenDeleteOrderModal] = useState<boolean>(false);
    const [selectedOrderId, setSelectedOrderId] = useState<string>("");

    const dispatch = useDispatch();
    const { resetSuccessModal, fetchUserOrders } = bindActionCreators(menuActions, dispatch);

    const authState: authState = useSelector((state: state) => state.auth);
    const menuState: menuState = useSelector((state: state) => state.menu);
    const userId: string = authState.userUid;
    const userOrders: Order[] = menuState.userOrders;
    const customerSubvention: number = authState.user.customerSubvention;

    useEffect(() => {
        fetchUserOrders(selectedDate.format("DD/MM/YYYY"), userId);
    }, []);

    const closeModal = (): void => {
        onHandleClose();
        resetSuccessModal();
    };

    const handleOpenDeleteOrderModal = (orderId: string): void => {
        setOpenDeleteOrderModal(true);
        setSelectedOrderId(orderId);
    };

    if (menuState.userOrdersLoading) return <Loader />;

    const isDeleteOrderButtonVisible: boolean = !(selectedDate.isSame(moment(), "day") && moment().format("HH") >= "09");
    const sortedOrders: Order[] = [...userOrders].sort((a, b) => a.createdAt > b.createdAt ? 1 : -1);
    const userTotalAmount: number = calculateTotalOrderAmount(sortedOrders);

    return (
        <div>
            <Modal open={open} onClose={onHandleClose}>
                <ModalOverflow>
                    <ModalDialog
                        variant="outlined"
                        aria-labelledby="user-orders-dialog-modal-title"
                        aria-describedby="user-orders-dialog-description"
                    >
                        <Typography id="user-orders-dialog-modal-title" level="h2">
                            Vaša porudžbina za: {selectedDate.format("DD.MM.YYYY.")}
                        </Typography>
                        <Divider />

                        {sortedOrders.length > 0 ?
                            <div>
                                {sortedOrders.map((order: Order, i: number) => (
                                    <div key={i}>
                                        <p>Broj porudžbine: <b>{order.id?.split("-", 2).join("-")}</b></p>
                                        {order.meals.map((meal: SelectedMeal, i: number) => (
                                            <div key={i} style={{ margin: "5px 0" }}>
                                                {meal.name} <b>{portionLabels[meal.size]} </b>
                                                <span>x {meal.amount}</span>
                                            </div>
                                        ))}
                                        {order.note &&
                                            <div><b>Napomena:</b> {order.note}</div>
                                        }

                                        {isDeleteOrderButtonVisible &&
                                            <Button
                                                label="Obriši porudžbinu"
                                                startIcon={<Delete />}
                                                className="btn_delete"
                                                handleClick={() => handleOpenDeleteOrderModal(order.id)}
                                            />}

                                        <Divider sx={{ my: 1.5 }} />
                                    </div>
                                ))}
                                <div className="orders_total_amount_container">
                                    <div className="total_amount">
                                        <span>Ukupno</span>
                                        <b className={`amount ${(customerSubvention > 0) && "strikethrough"}`}>
                                            {formatCurrency(userTotalAmount)} RSD
                                        </b>
                                    </div>
                                    {(customerSubvention > 0) &&
                                        <div className="total_amount">
                                            <span>Sa subvencijom</span>
                                            <b className="amount">
                                                {formatCurrency(userTotalAmount - customerSubvention > 0 ? userTotalAmount - customerSubvention : 0)} RSD
                                            </b>
                                        </div>
                                    }
                                </div>
                            </div>
                            :
                            <Typography
                                id="user-orders-dialog-description"
                                textColor="text.tertiary"
                                sx={{ px: 3, pt: 1, textAlign: "center" }}
                            >
                                Nema porudžbina
                            </Typography>
                        }
                        <Box
                            sx={{ display: "flex", gap: 1, justifyContent: "center", p: 2 }}
                        >
                            <Button
                                label="Zatvori"
                                className="btn_success"
                                handleClick={closeModal}
                            />
                        </Box>
                    </ModalDialog>
                </ModalOverflow>
            </Modal>

            <DeleteOrderModal
                open={openDeleteOrderModal}
                selectedOrderId={selectedOrderId}
                selectedDate={selectedDate.format("DD/MM/YYYY")}
                userId={userId}
                canDeleteOrder={isDeleteOrderButtonVisible}
                onHandleClose={() => setOpenDeleteOrderModal(false)}
            />
        </div>
    );
}

export default OrderForCurrentUserModal;