import React, { FC, useState, useCallback } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { state } from "../../index";
import * as authActions from "../../store/actions/authActions";
import authState from "../../store/reducers/authState";
import Logo from "./Logo";
import { Container, Nav, Navbar } from "react-bootstrap";
import { ListItemIcon, MenuItem, Menu, Box } from "@mui/material";
import { AccountCircle, Logout } from "@mui/icons-material";

interface NavbarItemProps {
    title: string;
    url: string;
}

const Navigation: FC = () => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [expanded, setExpanded] = useState<boolean>(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { logoutUser } = bindActionCreators(authActions, dispatch);

    const authState: authState = useSelector((state: state) => state.auth);
    const fullName: string = authState.user?.fullName;

    const handleOpenMenu = useCallback((e: React.MouseEvent<HTMLElement>): void => {
        setAnchorEl(e.currentTarget);
    }, []);

    const handleCloseMenu = useCallback((): void => {
        setAnchorEl(null);
    }, []);

    const handleClickLogout = useCallback((): void => {
        logoutUser();
        navigate("/");
    }, [logoutUser, navigate]);

    let navbarItems: NavbarItemProps[] = [];
    if (!authState.user) {
        navbarItems = [{ title: "Prijavi se", url: "/login" }];
    } else {
        switch (authState.user?.role) {
            case "admin":
                navbarItems = [
                    { title: "Korisnici", url: "/users" },
                    { title: "Podesavanja", url: "/settings" },
                    { title: "Izveštaji", url: "/reports" },
                    { title: "Porudžbine", url: "/orders" }
                ];
                break;
            case "kitchenChef":
                navbarItems = [{ title: "Porudžbine", url: "/orders" }];
                break;
            case "customerAdmin":
                navbarItems = [
                    { title: "Korisnici", url: "/users" },
                    { title: "Izveštaji", url: "/reports" }
                ];
                break;
            default:
                navbarItems = [];
        }
    }

    return (
        <Navbar collapseOnSelect expanded={expanded} expand="lg" fixed="top" className="bg-body-tertiary top_navigation">
            <Container>
                <Logo />
                <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={() => setExpanded(!expanded)} />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto">
                        <NavLink to="/" className="navbar_item" onClick={() => setExpanded(false)}>
                            Meni
                        </NavLink>
                        <NavLink to="/contact" className="navbar_item" onClick={() => setExpanded(false)}>
                            Kontakt
                        </NavLink>
                    </Nav>
                    <Nav>
                        {navbarItems.reverse().map(item => (
                            <NavLink
                                to={item.url}
                                className="navbar_item"
                                key={item.title}
                                onClick={() => setExpanded(false)}
                            >
                                {item.title}
                            </NavLink>
                        ))}
                        {authState.user && (
                            <>
                                <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
                                    <AccountCircle color="disabled" />
                                    <Box onClick={handleOpenMenu} className="logged_user_fullName">
                                        {fullName}
                                    </Box>
                                </Box>
                                <Menu
                                    anchorEl={anchorEl}
                                    open={Boolean(anchorEl)}
                                    onClose={handleCloseMenu}
                                    onClick={handleCloseMenu}
                                    PaperProps={{
                                        style: {
                                            transform: "translateY(15%)"
                                        },
                                        sx: {
                                            overflow: "visible",
                                            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                            mt: 1.5,
                                            "& .MuiAvatar-root": {
                                                width: 32,
                                                height: 32,
                                                ml: -0.5,
                                                mr: 1
                                            },
                                            "&:before": {
                                                content: '""',
                                                display: "block",
                                                position: "absolute",
                                                top: 0,
                                                right: 14,
                                                width: 10,
                                                height: 10,
                                                bgcolor: "background.paper",
                                                transform: "translateY(-50%) rotate(45deg)",
                                                zIndex: 0
                                            },
                                        },
                                    }}
                                    transformOrigin={{ horizontal: "right", vertical: "top" }}
                                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                                >
                                    <MenuItem onClick={handleClickLogout}>
                                        <ListItemIcon>
                                            <Logout fontSize="small" />
                                        </ListItemIcon>
                                        Odjavi se
                                    </MenuItem>
                                </Menu>
                            </>
                        )}
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default Navigation;     