import React, { FC, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { ContactData, ContactDataErrors, validateForm } from "./validationUtils";
import Input from "../UI/Input";
import LoadingButton from "../UI/LoadingButton";
import Container from "react-bootstrap/Container";
import { FormControl, IconButton, Divider } from "@mui/material";
import { Instagram, Send } from "@mui/icons-material";
import { toast } from "react-toastify";

const Contact: FC = () => {
    const recaptcha = useRef<ReCAPTCHA>(null);
    const [contactData, setContactData] = useState<ContactData>({
        fullName: "",
        phone: "",
        email: "",
        message: ""
    });
    const [errors, setErrors] = useState<ContactDataErrors>({
        fullName: "",
        phone: "",
        email: "",
        message: ""
    });
    const [loading, setLoading] = useState<boolean>(false);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const { name, value } = e.target;
        setContactData({ ...contactData, [name]: value });
        setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    };

    const handleSendMessage = async (e: React.FormEvent): Promise<void> => {
        e.preventDefault();
        let { valid, errors } = validateForm(contactData);
        const captchaValue = recaptcha.current?.getValue();

        if (valid && captchaValue) {
            setLoading(true);
            const verifyResponse = await fetch("https://ukusnoitackaserver.web.app/verify", {
                method: "POST",
                body: JSON.stringify({ captchaValue }),
                headers: {
                    "content-type": "application/json",
                }
            });
            const verifyData = await verifyResponse.json();

            if (verifyData.success) {
                //Send mail
                const sendMailResponse = await fetch("https://ukusnoitackaserver.web.app/sendMail", {
                    method: "POST",
                    headers: { "Content-type": "application/json" },
                    body: JSON.stringify({ contactData })
                });
                const sendMailData = await sendMailResponse.json();

                if (sendMailData.status === "success") {
                    recaptcha.current?.reset();
                    setContactData({
                        fullName: "",
                        phone: "",
                        email: "",
                        message: ""
                    });

                    toast.success("Mail je uspešno poslat.");
                } else {
                    toast.error("Nešto je pošlo naopako prilikom slanja maila.");
                }
                setLoading(false);
            } else {
                toast.error("Nešto je pošlo naopako prilikom validacije reCAPTCHE.");
                setLoading(false);
            }
        } else {
            if (!captchaValue) {
                toast.error("Molimo potvrdite da niste robot.");
            }
            setErrors(errors);
        }
    };

    return (
        <div>
            <Container style={{ marginTop: 40 }}>
                <div className="contact_box">
                    <div>
                        <Divider sx={{ mb: 1 }}>
                            <h3 className="main_title">Kontaktirajte nas</h3>
                        </Divider>
                        <div>
                            <Input
                                label="Ime i prezime"
                                type="text"
                                value={contactData.fullName}
                                name="fullName"
                                required
                                error={errors.fullName}
                                onChange={handleInputChange}
                            />
                            <div className="contact_single_row">
                                <Input
                                    label="Email"
                                    type="text"
                                    value={contactData.email}
                                    name="email"
                                    required
                                    error={errors.email}
                                    onChange={handleInputChange}
                                />
                                <Input
                                    label="Telefon"
                                    type="text"
                                    value={contactData.phone}
                                    name="phone"
                                    error={errors.phone}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <Input
                                label="Poruka"
                                type="text"
                                value={contactData.message}
                                multiline
                                rows={5}
                                name="message"
                                required
                                error={errors.message}
                                onChange={handleInputChange}
                            />
                            <FormControl sx={{ display: "flex", flexDirection: "row", alignItems: "center", mt: 3, mb: 1.5 }}>
                                <ReCAPTCHA
                                    ref={recaptcha}
                                    sitekey={process.env.REACT_APP_SITE_KEY as string}
                                />
                            </FormControl>
                            <FormControl sx={{ display: "flex", flexDirection: "row", alignItems: "center", my: 3 }}>
                                <LoadingButton
                                    label="Pošalji email"
                                    endIcon={<Send />}
                                    loading={loading}
                                    loadingPosition="end"
                                    className="btn_success"
                                    handleClick={handleSendMessage}
                                />
                            </FormControl>
                        </div>
                    </div>
                    <div className="contact_info">
                        <div className="contact_info_row">
                            <div><b>Adresa:</b></div>
                            <div>Tolstojeva 4, 21000 Novi Sad</div>
                        </div>
                        <div className="contact_info_row">
                            <div><b>Email:</b></div>
                            <a href="mailto: ukusnoitacka@gmail.com">ukusnoitacka@gmail.com</a>
                        </div>
                        <div className="contact_info_row">
                            <div><b>Telefon:</b></div>
                            <a href="tel:+38162224280">+38162 224280</a>
                        </div>
                        <div className="contact_info_row">
                            <IconButton
                                aria-label="instagram"
                                size="large"
                                className="rounded_button"
                                onClick={() => window.open("https://www.instagram.com/ukusno_i_tacka/")}
                            >
                                <Instagram fontSize="large" />
                            </IconButton>
                        </div>
                    </div>
                </div>
            </Container>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d295.2693930012109!2d19.83425897944976!3d45.2459383414038!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x475b11dddbe0a5e3%3A0x58442cd12e08d6fa!2sUkusno%20i%20ta%C4%8Dka!5e0!3m2!1sen!2srs!4v1724707559084!5m2!1sen!2srs"
                width="100%"
                height="450"
                style={{ border: "0" }}
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
            >
            </iframe>
        </div>
    );
};

export default Contact;