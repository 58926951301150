import { LoginData, LoginDataErrors } from "../Auth";
import { validateEmail } from "../../../helpers/validation";

export const validateForm = (loginData: LoginData): { valid: boolean; errors: LoginDataErrors } => {
    const errors: LoginDataErrors = {};

    //Validate email
    if (!loginData.email.trim()) {
        errors.email = "Polje je obavezno.";
    } else if (!validateEmail(loginData.email)) {
        errors.email = "Pogrešan format email adrese.";
    }

    //Validate password
    if (!loginData.password.trim()) {
        errors.password = "Polje je obavezno.";
    }

    return {
        valid: Object.keys(errors).length === 0,
        errors
    };
};