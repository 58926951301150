export const FETCH_EMPLOYEES_REQUEST = "FETCH_EMPLOYEES_REQUEST";
export const FETCH_EMPLOYEES_SUCCESS = "FETCH_EMPLOYEES_SUCCESS";
export const FETCH_EMPLOYEES_FAIL = "FETCH_EMPLOYEES_FAIL";

export const ADD_EMPLOYEE_REQUEST = "ADD_EMPLOYEE_REQUEST";
export const ADD_EMPLOYEE_SUCCESS = "ADD_EMPLOYEE_SUCCESS";
export const ADD_EMPLOYEE_FAIL = "ADD_EMPLOYEE_FAIL";

export const DELETE_EMPLOYEE_REQUEST = "DELETE_EMPLOYEE_REQUEST";
export const DELETE_EMPLOYEE_SUCCESS = "DELETE_EMPLOYEE_SUCCESS";
export const DELETE_EMPLOYEE_FAIL = "DELETE_EMPLOYEE_FAIL";

export interface FetchEmployeesRequestAction {
    type: typeof FETCH_EMPLOYEES_REQUEST;
}

export interface FetchEmployeesSuccessAction {
    type: typeof FETCH_EMPLOYEES_SUCCESS;
    payload: string;
}

export interface FetchEmployeesFailAction {
    type: typeof FETCH_EMPLOYEES_FAIL;
    payload: Error;
}

export interface AddEmployeeRequestAction {
    type: typeof ADD_EMPLOYEE_REQUEST;
}

export interface AddEmployeeSuccessAction {
    type: typeof ADD_EMPLOYEE_SUCCESS;
}

export interface AddEmployeeFailAction {
    type: typeof ADD_EMPLOYEE_FAIL;
    payload: Error;
}

export interface DeleteEmployeeRequestAction {
    type: typeof DELETE_EMPLOYEE_REQUEST;
}

export interface DeleteEmployeeSuccessAction {
    type: typeof DELETE_EMPLOYEE_SUCCESS;
}

export interface DeleteEmployeeFailAction {
    type: typeof DELETE_EMPLOYEE_FAIL;
    payload: Error;
}

export type ActionTypes =
    | FetchEmployeesRequestAction
    | FetchEmployeesSuccessAction
    | FetchEmployeesFailAction
    | AddEmployeeRequestAction
    | AddEmployeeSuccessAction
    | AddEmployeeFailAction
    | DeleteEmployeeRequestAction
    | DeleteEmployeeSuccessAction
    | DeleteEmployeeFailAction;