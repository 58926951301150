import { Meal } from "../reducers/menuState";

export const FETCH_MEALS_REQUEST = "FETCH_MEALS_REQUEST";
export const FETCH_MEALS_SUCCESS = "FETCH_MEALS_SUCCESS";
export const FETCH_MEALS_FAIL = "FETCH_MEALS_FAIL";

export const ADD_MEAL_REQUEST = "ADD_MEAL_REQUEST";
export const ADD_MEAL_SUCCESS = "ADD_MEAL_SUCCESS";
export const ADD_MEAL_FAIL = "ADD_MEAL_FAIL";

export const DELETE_MEAL_REQUEST = "DELETE_MEAL_REQUEST";
export const DELETE_MEAL_SUCCESS = "DELETE_MEAL_SUCCESS";
export const DELETE_MEAL_FAIL = "DELETE_MEAL_FAIL";

export const ADD_MENU_REQUEST = "ADD_MENU_REQUEST";
export const ADD_MENU_SUCCESS = "ADD_MENU_SUCCESS";
export const ADD_MENU_FAIL = "ADD_MENU_FAIL";

export const FETCH_USERS_REQUEST = "FETCH_USERS_REQUEST";
export const FETCH_USERS_SUCCESS = "FETCH_USERS_SUCCESS";
export const FETCH_USERS_FAIL = "FETCH_USERS_FAIL";

export interface FetchMealsRequestAction {
    type: typeof FETCH_MEALS_REQUEST;
}

export interface FetchMealsSuccessAction {
    type: typeof FETCH_MEALS_SUCCESS;
    payload: Meal[];
}

export interface FetchMealsFailAction {
    type: typeof FETCH_MEALS_FAIL;
    payload: Error;
}

export interface AddMealRequestAction {
    type: typeof ADD_MEAL_REQUEST;
}

export interface AddMealSuccessAction {
    type: typeof ADD_MEAL_SUCCESS;
}

export interface AddMealFailAction {
    type: typeof ADD_MEAL_FAIL;
    payload: Error;
}

export interface DeleteMealRequestAction {
    type: typeof DELETE_MEAL_REQUEST;
}

export interface DeleteMealSuccessAction {
    type: typeof DELETE_MEAL_SUCCESS;
}

export interface DeleteMealFailAction {
    type: typeof DELETE_MEAL_FAIL;
    payload: Error;
}

export interface AddMenuRequestAction {
    type: typeof ADD_MENU_REQUEST;
}

export interface AddMenuSuccessAction {
    type: typeof ADD_MENU_SUCCESS;
}

export interface AddMenuFailAction {
    type: typeof ADD_MENU_FAIL;
    payload: Error;
}

export interface FetchUsersRequestAction {
    type: typeof FETCH_USERS_REQUEST;
}

export interface FetchUsersSuccessAction {
    type: typeof FETCH_USERS_SUCCESS;
    payload: string;
}

export interface FetchUsersFailAction {
    type: typeof FETCH_USERS_FAIL;
    payload: Error;
}


export type ActionTypes =
    | FetchMealsRequestAction
    | FetchMealsSuccessAction
    | FetchMealsFailAction
    | AddMealRequestAction
    | AddMealSuccessAction
    | AddMealFailAction
    | DeleteMealRequestAction
    | DeleteMealSuccessAction
    | DeleteMealFailAction
    | AddMenuRequestAction
    | AddMenuSuccessAction
    | AddMenuFailAction
    | FetchUsersRequestAction
    | FetchUsersSuccessAction
    | FetchUsersFailAction;