import React, { FC, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { state } from "../../../index";
import * as actions from "../../../store/actions/authActions";
import authState from "../../../store/reducers/authState";
import { LoginData, LoginDataErrors } from "../Auth";
import { validateForm } from "./validationUtils";
import Input from "../../UI/Input";
import PasswordInput from "../../UI/PasswordInput";
import Button from "../../UI/Button";
import Container from "react-bootstrap/Container";
import { FormControl } from "@mui/material";

const Login: FC = () => {
    const [loginData, setLoginData] = useState<LoginData>({
        email: "",
        password: ""
    });
    const [errors, setErrors] = useState<LoginDataErrors>({
        email: "",
        password: ""
    });

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { loginUser } = bindActionCreators(actions, dispatch);

    const authState: authState = useSelector((state: state) => state.auth);

    useEffect(() => {
        //Redirect to home if already authenticated
        if (authState.token) {
            navigate("/");
        }
    }, [authState.token])

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const { name, value } = e.target;
        setLoginData({ ...loginData, [name]: value });
        setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    };

    const onEnterPressLogin = (e: React.KeyboardEvent<HTMLInputElement>): void => {
        //Handle login on Enter key press
        if (e.code === "Enter" || e.code === "NumpadEnter") {
            login();
        }
    }

    const handleLogin = (e: React.FormEvent): void => {
        e.preventDefault();
        login();
    };

    const login = (): void => {
        let { valid, errors } = validateForm(loginData);
        if (valid) {
            loginUser(loginData.email, loginData.password);
        } else {
            setErrors(errors);
        }
    }

    return (
        <Container>
            <div className="auth_wraper">
                <div className="auth_box">
                    <div className="auth_header">
                        <h2>Prijavite se</h2>
                    </div>
                    <div className="auth_form">
                        <Input
                            label="Email"
                            type="text"
                            value={loginData.email}
                            name="email"
                            required
                            error={errors.email}
                            onChange={handleInputChange}
                            onKeyUp={onEnterPressLogin}
                        />
                        <PasswordInput
                            label="Lozinka"
                            value={loginData.password}
                            name="password"
                            required
                            error={errors.password}
                            onChange={handleInputChange}
                            onKeyUp={onEnterPressLogin}
                        />
                        <FormControl sx={{ my: 1.5 }}>
                            <Button
                                label="Prijavite se"
                                className="btn_success"
                                handleClick={handleLogin}
                            />
                        </FormControl>
                        <FormControl sx={{ display: "flex", mb: 2 }}>
                            <Link to="/register" className="login_register_link">
                                Još niste član? Registrujte se
                            </Link>
                        </FormControl>
                    </div>
                </div>
            </div>
        </Container>
    );
};

export default Login;