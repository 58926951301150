import * as actionTypes from "../types/settingsActionTypes";
import settingsState from "./settingsState";
import { ActionTypes } from "../types/settingsActionTypes";

const initialState: settingsState = {
    meals: [],
    users: [],
    loading: false,
    error: null
};

const settingsReducer = (state = initialState, action: ActionTypes) => {
    switch (action.type) {
        case actionTypes.FETCH_MEALS_REQUEST:
            return {
                ...state,
                loading: true
            };

        case actionTypes.FETCH_MEALS_SUCCESS:
            return {
                ...state,
                loading: false,
                meals: action.payload
            };

        case actionTypes.FETCH_MEALS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            };

        case actionTypes.ADD_MEAL_REQUEST:
            return {
                ...state,
                loading: true
            };

        case actionTypes.ADD_MEAL_SUCCESS:
            return {
                ...state,
                loading: false
            };

        case actionTypes.ADD_MEAL_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            };

        case actionTypes.DELETE_MEAL_REQUEST:
            return {
                ...state
            };

        case actionTypes.DELETE_MEAL_SUCCESS:
            return {
                ...state
            };

        case actionTypes.DELETE_MEAL_FAIL:
            return {
                ...state,
                error: action.payload
            };

        case actionTypes.ADD_MENU_REQUEST:
            return {
                ...state,
                loading: true
            };

        case actionTypes.ADD_MENU_SUCCESS:
            return {
                ...state,
                loading: false
            };

        case actionTypes.ADD_MENU_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            };

        case actionTypes.FETCH_USERS_REQUEST:
            return {
                ...state
            };

        case actionTypes.FETCH_USERS_SUCCESS:
            return {
                ...state,
                users: action.payload
            };

        case actionTypes.FETCH_USERS_FAIL:
            return {
                ...state,
                error: action.payload
            };

        default:
            return state;
    }
};

export default settingsReducer;