import { v4 as uuidv4 } from "uuid";
import { AnyAction, Dispatch } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { db } from "../../config/firebase";
import { collection, getDocs, query, where, updateDoc, deleteDoc, setDoc, doc, QuerySnapshot, DocumentData, Query, QueryDocumentSnapshot, DocumentReference } from "firebase/firestore";
import * as actionTypes from "../types/usersActionTypes";
import { Employee } from "../../components/auth/Auth";
import { toast } from "react-toastify";

export const fetchEmployeesRequest = () => ({
    type: actionTypes.FETCH_EMPLOYEES_REQUEST,
});

export const fetchEmployeesSuccess = (data: Employee[]) => ({
    type: actionTypes.FETCH_EMPLOYEES_SUCCESS,
    payload: data
});

export const fetchEmployeesFail = (error: any) => ({
    type: actionTypes.FETCH_EMPLOYEES_FAIL,
    payload: error
});

export const fetchEmployees = (customerId: string) => {
    return async (dispatch: Dispatch) => {
        dispatch(fetchEmployeesRequest());
        try {
            const querySnapshot: QuerySnapshot<DocumentData> = await getDocs(collection(db, "customers", customerId, "employees"));
            const employees: Employee[] = querySnapshot.docs.map(doc => ({ ...doc.data() as Employee }));
            dispatch(fetchEmployeesSuccess(employees));
        } catch (error) {
            console.error(error);
            dispatch(fetchEmployeesFail(error));
        }
    };
};

export const addEmployeeRequest = () => ({
    type: actionTypes.ADD_EMPLOYEE_REQUEST,
});

export const addEmployeeSuccess = () => ({
    type: actionTypes.ADD_EMPLOYEE_SUCCESS
});

export const addEmployeeFail = (error: any) => ({
    type: actionTypes.ADD_EMPLOYEE_FAIL,
    payload: error
});

export const addEmployee = (employee: Employee, isAddMode: boolean) => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        dispatch(addEmployeeRequest());
        const id: string = isAddMode ? uuidv4() : employee.employeeId as string;

        try {
            await setDoc(doc(db, "customers", employee.customerId, "employees", id), {
                employeeId: id,
                customerId: employee.customerId,
                email: employee.email,
                fullName: employee.fullName,
                role: isAddMode ? "user" : employee.role
            });

            dispatch(addEmployeeSuccess());
            dispatch(fetchEmployees(employee.customerId));
            toast.success(`Usprešno ste ${isAddMode ? "dodali novog " : "sačuvali "} korisnika.`);
        } catch (error) {
            console.error(error);
            dispatch(addEmployeeFail(error));
        }
    };
};

export const deleteEmployeeRequest = () => ({
    type: actionTypes.DELETE_EMPLOYEE_REQUEST,
});

export const deleteEmployeeSuccess = () => ({
    type: actionTypes.DELETE_EMPLOYEE_SUCCESS
});

export const deleteEmployeeFail = (error: any) => ({
    type: actionTypes.DELETE_EMPLOYEE_FAIL,
    payload: error
});

export const deleteEmployee = (employee: Employee) => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        dispatch(deleteEmployeeRequest());
        try {
            //Delete user from customers/customerId/employees
            const customersQuery: Query<DocumentData> = query(collection(db, "customers", employee.customerId, "employees"), where("email", "==", employee.email));
            const querySnapshot: QuerySnapshot<DocumentData> = await getDocs(customersQuery);
            if (!querySnapshot.empty) {
                const docToDelete: QueryDocumentSnapshot<DocumentData> = querySnapshot.docs[0];
                await deleteDoc(doc(db, "customers", employee.customerId, "employees", docToDelete.id));
            }

            //Set inactive user in users
            const usersQuery: Query<DocumentData> = query(collection(db, "users"), where("email", "==", employee.email));
            const userSnapshot: QuerySnapshot<DocumentData> = await getDocs(usersQuery);
            if (!userSnapshot.empty) {
                const docToUpdate: QueryDocumentSnapshot<DocumentData> = userSnapshot.docs[0];
                const userId: string = docToUpdate.id;
                const userRef: DocumentReference<DocumentData> = doc(db, "users", userId);
                await updateDoc(userRef, {
                    isActive: false
                });
            }

            dispatch(deleteEmployeeSuccess());
            await dispatch(fetchEmployees(employee.customerId));
            toast.success("Usprešno ste obrisali korisnika.");
        } catch (error) {
            console.error(error);
            dispatch(deleteEmployeeFail(error));
        }
    };
};