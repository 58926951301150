import React, { FC, useState, useEffect } from "react";
import { DocumentData, Query, QuerySnapshot, collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../../config/firebase";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { state } from "../../../index";
import * as authActions from "../../../store/actions/authActions";
import * as userActions from "../../../store/actions/usersActions";
import authState from "../../../store/reducers/authState";
import { Customer, Employee } from "../../auth/Auth";
import { EmployeeErrors } from "../User";
import { validateForm } from "./validationUtils";
import Input from "../../UI/Input";
import Select from "../../UI/Select";
import Button from "../../UI/Button";
import { Modal, ModalClose, ModalDialog, Typography } from "@mui/joy";
import { FormControl, SelectChangeEvent } from "@mui/material";
import { Save } from "@mui/icons-material";
import { toast } from "react-toastify";

interface UserModalProps {
    open: boolean;
    isAddMode: boolean;
    selectedUser: Employee;
    onHandleClose: () => void;
}

const UserModal: FC<UserModalProps> = ({ open, isAddMode, selectedUser, onHandleClose }) => {
    const [employeeData, setEmployeeData] = useState<Employee>({
        employeeId: "",
        customerId: "",
        email: "",
        fullName: "",
        role: ""
    });
    const [errors, setErrors] = useState<EmployeeErrors>({
        fullName: "",
        email: ""
    });

    const dispatch = useDispatch();
    const { fetchCustomers } = bindActionCreators(authActions, dispatch);
    const { addEmployee: addUser } = bindActionCreators(userActions, dispatch);

    const authState: authState = useSelector((state: state) => state.auth);
    const userRole: string = authState.user.role;
    const customers: Customer[] = authState.customers;

    useEffect(() => {
        fetchCustomers();
    }, []);

    useEffect(() => {
        if (isAddMode) {
            //add mode
            setEmployeeData({ ...employeeData, customerId: authState.user.customerId });
        } else {
            //edit mode
            setEmployeeData(selectedUser);
        }
    }, []);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const { name, value } = e.target;
        setEmployeeData({ ...employeeData, [name]: value });
        setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    };

    const handleSelectChange = (e: SelectChangeEvent): void => {
        const { name, value } = e.target;
        setEmployeeData({ ...employeeData, [name]: value });
        setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    };

    const checkIfEmailExists = async (email: string, customerId: string): Promise<boolean> => {
        const usersQuery: Query<DocumentData> = query(collection(db, "customers", customerId, "employees"), where("email", "==", email));
        const querySnapshot: QuerySnapshot<DocumentData> = await getDocs(usersQuery);
        return !querySnapshot.empty;
    }

    const handleAddOrEditUser = async (e: React.FormEvent): Promise<void> => {
        e.preventDefault();

        let { valid, errors } = validateForm(employeeData);
        if (!valid) {
            setErrors(errors);
        } else if (isAddMode && await checkIfEmailExists(employeeData.email, employeeData.customerId)) {
            toast.error("Unešena email adresa je prethodno uneta.");
        } else {
            addUser(employeeData, isAddMode);
            onHandleClose();
        }
    };

    return (
        <Modal open={open} onClose={onHandleClose}>
            <ModalDialog
                aria-labelledby="basic-modal-dialog-title"
                aria-describedby="basic-modal-dialog-description"
                sx={{ maxWidth: 500 }}
            >
                <ModalClose variant="outlined" />
                <Typography id="basic-modal-dialog-title" level="h2">
                    {isAddMode ? "Dodaj novog korisnika" : "Izmeni korisnika"}
                </Typography>
                {userRole === "admin" &&
                    <Select
                        label="Kompanija"
                        value={employeeData.customerId}
                        options={customers.map(customer => ({ key: customer.id, value: customer.name }))}
                        disabled={!isAddMode}
                        name="customerId"
                        onSelectChange={handleSelectChange}
                    />}
                <Input
                    label="Ime i prezime"
                    type="text"
                    value={employeeData.fullName}
                    name="fullName"
                    required
                    error={errors.fullName}
                    onChange={handleInputChange}
                />
                <Input
                    label="Email"
                    type="text"
                    value={employeeData.email}
                    name="email"
                    disabled={!isAddMode}
                    required
                    error={errors.email}
                    onChange={handleInputChange}
                />
                <FormControl sx={{ my: 1.5 }}>
                    <Button
                        label="Sačuvaj"
                        startIcon={<Save />}
                        className="btn_success"
                        handleClick={handleAddOrEditUser}
                    />
                </FormControl>
            </ModalDialog>
        </Modal>
    );
};

export default UserModal;