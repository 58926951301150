import React, { FC } from "react";
import { Portion } from "../../../store/reducers/menuState";
import Input from "../../UI/Input";
import Select from "../../UI/Select";
import { FormControl, SelectChangeEvent } from "@mui/material"

interface PortionRowProps {
    index: number;
    portion: Portion;
    handlePortionSelectChange: (e: SelectChangeEvent, index: number) => void;
    handlePortionInputChange: (e: React.ChangeEvent<HTMLInputElement>, index: number) => void;
}

const portions = [
    { id: "regular", value: "Obrok" },
    { id: "small", value: "Mala" },
    { id: "big", value: "Velika" }
];

const PortionRow: FC<PortionRowProps> = ({ index, portion, handlePortionSelectChange, handlePortionInputChange }) => {
    const { size, price } = portion;

    return (
        <div className="portion_row_container">
            <FormControl sx={{ width: "170px" }}>
                <Select
                    label="Veličina porcije"
                    value={size}
                    options={portions.map(portion => ({ key: portion.id, value: portion.value }))}
                    name="size"
                    onSelectChange={(e) => handlePortionSelectChange(e, index)}
                />
            </FormControl>
            <FormControl sx={{ width: "170px", ml: { sm: 1.5 } }}>
                <Input
                    label="Cena"
                    type="number"
                    value={price}
                    name="price"
                    onChange={(e) => handlePortionInputChange(e, index)}
                />
            </FormControl>
        </div>
    );
};

export default PortionRow;