import React, { FC } from "react";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import * as userActions from "../../../store/actions/usersActions";
import { Employee } from "../../auth/Auth";
import { Box, Button, Divider, Modal, ModalDialog, Typography } from "@mui/joy";
import { Delete, WarningRounded } from "@mui/icons-material";

interface UserModalProps {
    open: boolean;
    selectedUser: Employee;
    onHandleClose: () => void;
}

const DeleteUserModal: FC<UserModalProps> = ({ open, selectedUser, onHandleClose }) => {
    const dispatch = useDispatch();
    const { deleteEmployee: deleteUser } = bindActionCreators(userActions, dispatch);

    const handleDeleteUser = (e: React.FormEvent): void => {
        e.preventDefault();
        deleteUser(selectedUser);
        onHandleClose();
    };

    return (
        <Modal open={open} onClose={onHandleClose}>
            <ModalDialog
                variant="outlined"
                role="alertdialog"
                aria-labelledby="alert-dialog-modal-title"
                aria-describedby="alert-dialog-modal-description"
            >
                <Typography id="alert-dialog-modal-title" level="h2" startDecorator={<WarningRounded />}>
                    Brisanje korisnika
                </Typography>
                <Divider />
                <Typography id="alert-dialog-modal-description" textColor="text.tertiary">
                    Da li ste sigurni da želite da obrišete korisnika <b>{selectedUser.email}</b>?
                </Typography>
                <Box sx={{ display: "flex", gap: 1, justifyContent: "flex-end", pt: 2 }}>
                    <Button
                        variant="plain"
                        color="neutral"
                        onClick={() => onHandleClose()}
                    >
                        Otkaži
                    </Button>
                    <Button
                        variant="solid"
                        color="danger"
                        startDecorator={<Delete />}
                        onClick={handleDeleteUser}
                    >
                        Obriši korisnika
                    </Button>
                </Box>
            </ModalDialog>
        </Modal>
    );
}

export default DeleteUserModal;