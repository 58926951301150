import React from "react";
import { Box, IconButton } from "@mui/material";
import { FirstPage, KeyboardArrowLeft, KeyboardArrowRight, LastPage } from "@mui/icons-material";

interface TablePaginationActionsProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onPageChange: (e: React.MouseEvent<HTMLButtonElement>, newPage: number) => void;
}

const TablePaginationActions = ({ count, page, rowsPerPage, onPageChange }: TablePaginationActionsProps) => {
    const handleFirstPageButtonClick = (e: React.MouseEvent<HTMLButtonElement>): void => {
        onPageChange(e, 0);
    };

    const handleBackButtonClick = (e: React.MouseEvent<HTMLButtonElement>): void => {
        onPageChange(e, page - 1);
    };

    const handleNextButtonClick = (e: React.MouseEvent<HTMLButtonElement>): void => {
        onPageChange(e, page + 1);
    };

    const handleLastPageButtonClick = (e: React.MouseEvent<HTMLButtonElement>): void => {
        onPageChange(e, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                aria-label="first page"
                disabled={page === 0}
                onClick={handleFirstPageButtonClick}
            >
                <FirstPage />
            </IconButton>
            <IconButton
                aria-label="previous page"
                disabled={page === 0}
                onClick={handleBackButtonClick}
            >
                <KeyboardArrowLeft />
            </IconButton>
            <IconButton
                aria-label="next page"
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                onClick={handleNextButtonClick}
            >
                <KeyboardArrowRight />
            </IconButton>
            <IconButton
                aria-label="last page"
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                onClick={handleLastPageButtonClick}
            >
                <LastPage />
            </IconButton>
        </Box>
    );
}

export default TablePaginationActions;