import React, { FC } from "react";
import { LoadingButton as MuiLoadingButton } from "@mui/lab";

type loadingPositionType = "start" | "end" | "center";

interface LoadingButtonProps {
    label?: string;
    startIcon?: React.ReactNode;
    endIcon?: React.ReactNode;
    disabled?: boolean;
    loading?: boolean;
    loadingPosition?: loadingPositionType;
    className?: string;
    handleClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const LoadingButton: FC<LoadingButtonProps> = ({ label, startIcon, endIcon, disabled, loading, loadingPosition, className, handleClick }) => {
    return (
        <MuiLoadingButton
            startIcon={startIcon}
            endIcon={endIcon}
            disabled={disabled}
            loading={loading}
            loadingPosition={loadingPosition}
            className={className}
            variant="contained"
            onClick={handleClick}
        >
            {label}
        </MuiLoadingButton>
    );
};

export default LoadingButton;