import React, { FC } from "react";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import * as menuActions from "../../../store/actions/menuActions";
import Button from "../../UI/Button";
import { Box, Modal, ModalDialog, Typography } from "@mui/joy";
import { CheckCircleOutline } from "@mui/icons-material";

interface SuccessfulOrderModalProps {
    open: boolean;
    orderId: string;
    onHandleClose: () => void;
}

const SuccessfulOrderModal: FC<SuccessfulOrderModalProps> = ({ open, orderId, onHandleClose }) => {
    const dispatch = useDispatch();
    const { resetSuccessModal } = bindActionCreators(menuActions, dispatch);

    const closeModal = (): void => {
        onHandleClose();
        resetSuccessModal();
    };

    return (
        <Modal open={open}>
            <ModalDialog
                variant="outlined"
                aria-labelledby="success-dialog-modal-title"
                aria-describedby="success-dialog-modal-description"
                sx={{ p: 0, border: "none" }}
            >
                <Typography
                    id="success-dialog-modal-title"
                    level="h2"
                    startDecorator={<CheckCircleOutline />}
                    sx={{
                        p: 2.5, backgroundColor: "#BBD870", color: "#5C5E60", justifyContent: "center",
                        borderTopLeftRadius: "8px", borderTopRightRadius: "8px"
                    }}
                >
                    Uspešna porudžbina
                </Typography>
                <Typography
                    id="success-dialog-modal-description"
                    textColor="text.tertiary"
                    sx={{ px: 3, pt: 1, textAlign: "center" }}
                >
                    Hvala vam puno na porudžbini. Broj vaše porudžbine je:
                    <b style={{ display: "block" }}>{orderId}</b>
                </Typography>
                <Box
                    sx={{ display: "flex", gap: 1, justifyContent: "center", p: 2 }}
                >
                    <Button
                        label="Zatvori"
                        className="btn_success"
                        handleClick={closeModal}
                    />
                </Box>
            </ModalDialog>
        </Modal>
    );
}

export default SuccessfulOrderModal;