import { v4 as uuidv4 } from "uuid";
import { AnyAction, Dispatch } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { db } from "../../config/firebase";
import { collection, getDocs, deleteDoc, setDoc, doc, QuerySnapshot, DocumentData, Query, query, where } from "firebase/firestore";
import * as actionTypes from "../types/settingsActionTypes";
import { Meal } from "../reducers/menuState";
import { User } from "../reducers/authState";
import { toast } from "react-toastify";

export const fetchMealsRequest = () => ({
    type: actionTypes.FETCH_MEALS_REQUEST,
});

export const fetchMealsSuccess = (data: Meal[]) => ({
    type: actionTypes.FETCH_MEALS_SUCCESS,
    payload: data
});

export const fetchMealsFail = (error: any) => ({
    type: actionTypes.FETCH_MEALS_FAIL,
    payload: error
});

export const fetchMeals = () => {
    return async (dispatch: Dispatch) => {
        dispatch(fetchMealsRequest());
        try {
            const querySnapshot: QuerySnapshot<DocumentData> = await getDocs(collection(db, "meals"));
            const meals: Meal[] = querySnapshot.docs.map(doc => ({ ...doc.data() as Meal }));
            dispatch(fetchMealsSuccess(meals));
        } catch (error) {
            console.error(error);
            dispatch(fetchMealsFail(error));
        }
    };
};

export const addMealRequest = () => ({
    type: actionTypes.ADD_MEAL_REQUEST,
});

export const addMealSuccess = () => ({
    type: actionTypes.ADD_MEAL_SUCCESS
});

export const addMealFail = (error: any) => ({
    type: actionTypes.ADD_MEAL_FAIL,
    payload: error
});

export const addMeal = (meal: Meal, isAddMode: boolean) => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        dispatch(addMealRequest());
        const id: string = isAddMode ? uuidv4() : meal.id;
        const newMeal = {
            ...meal,
            id,
            soldOut: false,
            meal: "lunch",
        };

        try {
            await setDoc(doc(db, "meals", id), newMeal);
            dispatch(addMealSuccess());
            await dispatch(fetchMeals());
            toast.success(`Usprešno ste ${isAddMode ? "dodali " : "sačuvali "} jelo.`);
        } catch (error) {
            console.error(error);
            dispatch(addMealFail(error));
        }
    };
};

export const deleteMealRequest = () => ({
    type: actionTypes.DELETE_MEAL_REQUEST,
});

export const deleteMealSuccess = () => ({
    type: actionTypes.DELETE_MEAL_SUCCESS
});

export const deleteMealFail = (error: any) => ({
    type: actionTypes.DELETE_MEAL_FAIL,
    payload: error
});

export const deleteMeal = (meal: Meal) => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        dispatch(deleteMealRequest());
        try {
            await deleteDoc(doc(db, "meals", meal.id));
            dispatch(deleteMealSuccess());
            await dispatch(fetchMeals());
            toast.success("Uspešno ste obrisali jelo.");
        } catch (error) {
            console.error(error);
            dispatch(deleteMealFail(error));
        }
    };
};

export const addMenuRequest = () => ({
    type: actionTypes.ADD_MENU_REQUEST,
});

export const addMenuSuccess = () => ({
    type: actionTypes.ADD_MENU_SUCCESS
});

export const addMenuFail = (error: any) => ({
    type: actionTypes.ADD_MENU_FAIL,
    payload: error
});

export const addMenu = (meals: Meal[], date: number) => {
    return async (dispatch: Dispatch) => {
        dispatch(addMenuRequest());
        let id: string = uuidv4();
        const menuData = {
            id,
            date,
            meals,
        };

        try {
            await setDoc(doc(db, "menu", id), menuData);
            dispatch(addMenuSuccess());
            toast.success("Uspešno ste dodali jelovnik.");
        } catch (error) {
            console.error(error);
            dispatch(addMenuFail(error));
        }
    };
};

export const fetchUsersRequest = () => ({
    type: actionTypes.FETCH_USERS_REQUEST,
});

export const fetchUsersSuccess = (data: User[]) => ({
    type: actionTypes.FETCH_USERS_SUCCESS,
    payload: data
});

export const fetchUsersFail = (error: any) => ({
    type: actionTypes.FETCH_USERS_FAIL,
    payload: error
});

export const fetchUsers = (customerId: string) => {
    return async (dispatch: Dispatch) => {
        dispatch(fetchUsersRequest());
        try {
            const usersQuery: Query<DocumentData> = query(collection(db, "users"), where("customerId", "==", customerId));
            const userSnapshot: QuerySnapshot<DocumentData> = await getDocs(usersQuery);
            const users: User[] = userSnapshot.docs.map(doc => ({ ...doc.data() as User }));
            dispatch(fetchUsersSuccess(users));
        } catch (error) {
            console.error(error);
            dispatch(fetchUsersFail(error));
        }
    };
};