import { EmployeeErrors } from "../User";
import { Employee } from "../../auth/Auth";
import { validateEmail } from "../../../helpers/validation";

export const validateForm = (employee: Employee): { valid: boolean; errors: EmployeeErrors } => {
    const errors: EmployeeErrors = {};

    //Validate email
    if (!employee.email.trim()) {
        errors.email = "Polje je obavezno.";
    } else if (!validateEmail(employee.email)) {
        errors.email = "Pogrešan format email adrese.";
    }

    //Validate fullName
    if (!employee.fullName.trim()) {
        errors.fullName = "Polje je obavezno.";
    }

    return {
        valid: Object.keys(errors).length === 0,
        errors
    };
};