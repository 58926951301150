import * as actionTypes from "../types/usersActionTypes";
import usersState from "./usersState";
import { ActionTypes } from "../types/usersActionTypes";

const initialState: usersState = {
    employees: [],
    loading: false,
    error: null
};

const usersReducer = (state = initialState, action: ActionTypes) => {
    switch (action.type) {
        case actionTypes.FETCH_EMPLOYEES_REQUEST:
            return {
                ...state,
                loading: true
            };

        case actionTypes.FETCH_EMPLOYEES_SUCCESS:
            return {
                ...state,
                loading: false,
                employees: action.payload
            };

        case actionTypes.FETCH_EMPLOYEES_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            };

        case actionTypes.ADD_EMPLOYEE_REQUEST:
            return {
                ...state
            };

        case actionTypes.ADD_EMPLOYEE_SUCCESS:
            return {
                ...state
            };

        case actionTypes.ADD_EMPLOYEE_FAIL:
            return {
                ...state,
                error: action.payload
            };

        case actionTypes.DELETE_EMPLOYEE_REQUEST:
            return {
                ...state
            };

        case actionTypes.DELETE_EMPLOYEE_SUCCESS:
            return {
                ...state
            };

        case actionTypes.DELETE_EMPLOYEE_FAIL:
            return {
                ...state,
                error: action.payload
            };

        default:
            return state;
    }
};

export default usersReducer;