import React, { FC, useState, useEffect } from "react";
import { DocumentData, Query, QuerySnapshot, collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../config/firebase";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { state } from "../../index";
import * as authActions from "../../store/actions/authActions";
import authState from "../../store/reducers/authState";
import { Order } from "../Menu/Menu";
import { Customer } from "../auth/Auth";
import { calculateTotalOrderAmount, calculateTotalCustomerSubvention, formatCurrency } from "../../helpers/utils";
import moment, { Moment } from "moment";
import "moment/locale/sr";
import Loader from "../UI/Loader";
import Input from "../UI/Input";
import Select from "../UI/Select";
import DatePicker from "../UI/DatePicker";
import Button from "../UI/Button";
import TablePaginationActions from "../UI/TablePaginationActions";
import Container from "react-bootstrap/Container";
import { FormControl, Divider, Paper, SelectChangeEvent, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow } from "@mui/material";
import { Description } from "@mui/icons-material";
import { toast } from "react-toastify";

interface ReportData {
    customerId: string;
    startDate: Moment;
    endDate: Moment;
}

const Reports: FC = () => {
    const [reportData, setReportData] = useState<ReportData>({
        customerId: "",
        startDate: moment(),
        endDate: moment()
    });
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [orders, setOrders] = useState<Order[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const dispatch = useDispatch();
    const { fetchCustomers } = bindActionCreators(authActions, dispatch);

    const authState: authState = useSelector((state: state) => state.auth);
    const userRole: string = authState.user.role;
    const customers: Customer[] = authState.customers;
    const customerSubvention: number = authState.user.customerSubvention;

    useEffect(() => {
        fetchCustomers();
    }, []);

    const handleSelectChange = (e: SelectChangeEvent): void => {
        const { name, value } = e.target;
        setReportData({ ...reportData, [name]: value });
    };

    const handleStartDateChange = (date: Moment): void => {
        if (reportData.endDate < date) {
            setReportData({ ...reportData, startDate: date, endDate: date });
        } else {
            setReportData({ ...reportData, startDate: date });
        }
    };

    const handleEndDateChange = (date: Moment): void => {
        if (reportData.startDate > date) {
            setReportData({ ...reportData, startDate: date, endDate: date });
        } else {
            setReportData({ ...reportData, endDate: date });
        }
    };

    const handleChangePage = (e: React.MouseEvent<HTMLButtonElement> | null, newPage: number): void => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setRowsPerPage(parseInt(e.target.value, 10));
        setPage(0);
    };

    const handleGenerateReport = async (): Promise<void> => {
        setLoading(true);
        try {
            const customerId: string = userRole === "admin" ? reportData.customerId : authState.user.customerId;
            let usersResult: Query<DocumentData>;
            if (customerId.length) {
                //For customer admin user
                usersResult = query(
                    collection(db, "orders"),
                    where("customerId", "==", customerId),
                    where("deliveryTimestamp", ">=", reportData.startDate.startOf("day").unix()),
                    where("deliveryTimestamp", "<=", reportData.endDate.endOf("day").unix()));
            } else {
                //For admin user
                usersResult = query(
                    collection(db, "orders"),
                    where("deliveryTimestamp", ">=", reportData.startDate.startOf("day").unix()),
                    where("deliveryTimestamp", "<=", reportData.endDate.endOf("day").unix()));
            }
            const querySnapshot: QuerySnapshot<DocumentData> = await getDocs(usersResult);
            const ordersData: Order[] = [...querySnapshot.docs.map(doc => ({ ...doc.data() as Order }))];
            setLoading(false);
            setOrders(ordersData);
        } catch (error) {
            setLoading(false);
            console.error("Something get wrong while generating the report ", error);
            toast.error("Nešto je pošlo naopako pri generisanju izveštaja.");
        }
    };

    const filteredOrders: Order[] = orders.filter(order =>
        order.createdUserFullName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        order.createdUserEmail.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const groupedOrders: Order[][] = [];
    const groupOrdersByUserIdAndDate = filteredOrders.reduce((acc: Record<string, Record<string, Order[]>>, order: Order) => {
        const userId = order.createdUserId;
        const deliveryDate = order.deliveryDate;

        acc[userId] = acc[userId] || {};
        acc[userId][deliveryDate] = [...(acc[userId][deliveryDate] || []), order];

        return acc;
    }, {});

    //Create a new array for each combination of createdUserId and deliveryDate
    Object.keys(groupOrdersByUserIdAndDate).forEach(userId => {
        Object.keys(groupOrdersByUserIdAndDate[userId]).forEach(deliveryDate => {
            groupedOrders.push(groupOrdersByUserIdAndDate[userId][deliveryDate]);
        });
    });

    const calculatedTotalAmount: number = calculateTotalOrderAmount(filteredOrders);
    const calculatedSubvention: number = calculateTotalCustomerSubvention(filteredOrders);

    if (loading) return <Loader />;

    return (
        <Container style={{ marginTop: 40 }}>
            <Divider sx={{ mb: 1 }}>
                <h3 className="main_title">Izveštaji</h3>
            </Divider>
            <div className="input_container">
                {userRole === "admin" &&
                    <FormControl>
                        <Select
                            label="Kompanija"
                            value={reportData.customerId}
                            options={customers.map(customer => ({ key: customer.id, value: customer.name }))}
                            name="customerId"
                            onSelectChange={handleSelectChange}
                        />
                    </FormControl>}
                <DatePicker
                    label="Od"
                    value={reportData.startDate}
                    onChange={handleStartDateChange}
                />
                <DatePicker
                    label="Do"
                    value={reportData.endDate}
                    onChange={handleEndDateChange}
                />
                <Button
                    label="Generiši izveštaj"
                    startIcon={<Description />}
                    className="btn_success"
                    handleClick={handleGenerateReport}
                />
            </div>
            <div className="search_input_container">
                <Input
                    label="Pretraga"
                    type="text"
                    value={searchTerm}
                    onChange={(e) => { setSearchTerm(e.target.value) }}
                />
            </div>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow
                            sx={{ backgroundColor: "#E6B238" }}
                        >
                            <TableCell sx={{ color: "#fff" }}>Broj porudžbine</TableCell>
                            <TableCell sx={{ color: "#fff" }}>Ime i prezime</TableCell>
                            <TableCell sx={{ color: "#fff" }}>Email</TableCell>
                            <TableCell sx={{ color: "#fff" }}>Poručeno za</TableCell>
                            {customerSubvention > 0 &&
                                <>
                                    <TableCell sx={{ color: "#fff", textAlign: "right" }}>Zaposleni</TableCell>
                                    <TableCell sx={{ color: "#fff", textAlign: "right" }}>Subvencija</TableCell>
                                </>
                            }
                            <TableCell sx={{ color: "#fff", textAlign: "right" }}>Ukupan iznos</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(rowsPerPage > 0
                            ? groupedOrders.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            : groupedOrders
                        ).map(orders => (
                            <TableRow
                                key={orders[0].id}
                                sx={{
                                    "&:nth-of-type(odd)": {
                                        backgroundColor: "rgba(0,0,0,0.04)"
                                    },
                                    "&:last-child td, &:last-child th": { border: 0 }
                                }}
                            >
                                <TableCell>
                                    {orders.map(order => (
                                        <div key={order.id}>{order.id?.split("-", 2).join("-")}</div>
                                    ))}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {orders[0].createdUserFullName}
                                </TableCell>
                                <TableCell>{orders[0].createdUserEmail}</TableCell>
                                <TableCell>{orders[0].deliveryDate}</TableCell>
                                {customerSubvention > 0 &&
                                    <>
                                        <TableCell sx={{ textAlign: "right" }}>
                                            {formatCurrency(calculateTotalOrderAmount(orders) - calculateTotalCustomerSubvention(orders))} RSD
                                        </TableCell>
                                        <TableCell sx={{ textAlign: "right" }}>
                                            {formatCurrency(calculateTotalCustomerSubvention(orders))} RSD
                                        </TableCell>
                                    </>
                                }
                                <TableCell sx={{ textAlign: "right" }}>
                                    {formatCurrency(calculateTotalOrderAmount(orders))} RSD
                                </TableCell>
                            </TableRow>
                        ))}
                        <TableRow>
                            {customerSubvention > 0 &&
                                <>
                                    <TableCell sx={{ textAlign: "right", fontWeight: "bold", fontSize: 15 }} colSpan={5}>
                                        <div>{formatCurrency(calculatedTotalAmount - calculatedSubvention)} RSD</div>
                                    </TableCell>
                                    <TableCell sx={{ textAlign: "right", fontWeight: "bold", fontSize: 15 }}>
                                        <div>{formatCurrency(calculatedSubvention)} RSD</div>
                                    </TableCell>
                                </>
                            }
                            <TableCell sx={{ textAlign: "right", fontWeight: "bold", fontSize: 15 }} colSpan={5}>
                                <div>Ukupno: {formatCurrency(calculatedTotalAmount)} RSD</div>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                labelRowsPerPage="Redova po stranici"
                                count={groupedOrders.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                colSpan={customerSubvention > 0 ? 7 : 5}
                                rowsPerPageOptions={[10, 50, 100, { label: "Svi", value: -1 }]}
                                labelDisplayedRows={({ from, to, count }) =>
                                    `${from}–${to} od ${count !== -1 ? count : `više od ${to}`}`
                                }
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </Container>
    );
};

export default Reports;