import * as actionTypes from "../types/menuActionTypes";
import menuState from "./menuState";
import { ActionTypes } from "../types/menuActionTypes";

const initialState: menuState = {
    menu: {
        date: 0,
        id: "",
        meals: []
    },
    oatmeals: [],
    loading: false,
    orders: [],
    status: "",
    orderId: "",
    userOrders: [],
    userOrdersLoading: false,
    incomingOrders: [],
    ordersUnsubscribe: null,
    incomingOrdersUnsubscribe: null,
    disabledOatmealDates: [],
    error: null
};

export enum STATUS_ENUM {
    SUCCESS = "success",
    FAIL = "fail"
}

const menuReducer = (state = initialState, action: ActionTypes) => {
    switch (action.type) {
        case actionTypes.FETCH_MENU_REQUEST:
            return {
                ...state,
                loading: true
            };

        case actionTypes.FETCH_MENU_SUCCESS:
            return {
                ...state,
                loading: false,
                menu: action.payload
            };

        case actionTypes.FETCH_MENU_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            };

        case actionTypes.SAVE_ORDER_REQUEST:
            return {
                ...state
            };

        case actionTypes.SAVE_ORDER_SUCCESS:
            const orderId: string = action.payload.id.split("-", 2).join("-");
            return {
                ...state,
                status: STATUS_ENUM.SUCCESS,
                orderId: orderId,
                userOrders: [...state.userOrders, action.payload]
            };

        case actionTypes.SAVE_ORDER_FAIL:
            return {
                ...state,
                error: action.payload,
                status: STATUS_ENUM.FAIL,
                orderId: ""
            };

        case actionTypes.RESET_SUCCESS_MODAL:
            return {
                ...state,
                error: "",
                status: "",
                orderId: ""
            };

        case actionTypes.FETCH_ORDERS_REQUEST:
            return {
                ...state,
                loading: true
            };

        case actionTypes.FETCH_ORDERS_SUCCESS:
            return {
                ...state,
                loading: false,
                orders: action.payload
            };

        case actionTypes.FETCH_ORDERS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            };

        case actionTypes.SET_ORDERS_UNSUBSCRIBE:
            return {
                ...state,
                orders: [],
                ordersUnsubscribe: action.payload
            };

        case actionTypes.RESET_ORDERS_UNSUBSCRIBE:
            if (state.ordersUnsubscribe) {
                state.ordersUnsubscribe();
            }
            return {
                ...state,
                ordersUnsubscribe: null
            };

        case actionTypes.UPDATE_TO_FINISHED_ORDER_REQUEST:
            return {
                ...state
            };

        case actionTypes.UPDATE_TO_FINISHED_ORDER_SUCCESS:
            return {
                ...state
            };

        case actionTypes.UPDATE_TO_FINISHED_ORDER_FAIL:
            return {
                ...state,
                error: action.payload
            };

        case actionTypes.FETCH_USER_ORDERS_REQUEST:
            return {
                ...state,
                userOrdersLoading: true
            };

        case actionTypes.FETCH_USER_ORDERS_SUCCESS:
            return {
                ...state,
                userOrders: action.payload,
                userOrdersLoading: false
            };

        case actionTypes.FETCH_USER_ORDERS_FAIL:
            return {
                ...state,
                error: action.payload,
                userOrdersLoading: false
            };

        case actionTypes.UPDATE_MEAL_TO_SOLDOUT_REQUEST:
            return {
                ...state
            };

        case actionTypes.UPDATE_MEAL_TO_SOLDOUT_SUCCESS:
            return {
                ...state
            };

        case actionTypes.UPDATE_MEAL_TO_SOLDOUT_FAIL:
            return {
                ...state,
                error: action.payload
            };

        case actionTypes.FETCH_INCOMING_ORDERS_REQUEST:
            return {
                ...state
            };

        case actionTypes.FETCH_INCOMING_ORDERS_SUCCESS:
            return {
                ...state,
                incomingOrders: action.payload
            };

        case actionTypes.FETCH_INCOMING_ORDERS_FAIL:
            return {
                ...state,
                error: action.payload
            };

        case actionTypes.SET_INCOMING_ORDERS_UNSUBSCRIBE:
            return {
                ...state,
                incomingOrders: [],
                incomingOrdersUnsubscribe: action.payload
            };

        case actionTypes.RESET_INCOMING_ORDERS_UNSUBSCRIBE:
            if (state.incomingOrdersUnsubscribe) {
                state.incomingOrdersUnsubscribe();
            }
            return {
                ...state,
                incomingOrdersUnsubscribe: null
            };

        case actionTypes.FETCH_OATMEALS_REQUEST:
            return {
                ...state,
                loading: true
            };

        case actionTypes.FETCH_OATMEALS_SUCCESS:
            return {
                ...state,
                loading: false,
                oatmeals: action.payload
            };

        case actionTypes.FETCH_OATMEALS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            };

        case actionTypes.DELETE_USER_ORDER_REQUEST:
            return {
                ...state
            };

        case actionTypes.DELETE_USER_ORDER_SUCCESS:
            return {
                ...state
            };

        case actionTypes.DELETE_USER_ORDER_FAIL:
            return {
                ...state,
                error: action.payload
            };

        case actionTypes.FETCH_DISABLED_OATMEAL_DATES_REQUEST:
            return {
                ...state,
                loading: true
            };

        case actionTypes.FETCH_DISABLED_OATMEAL_DATES_SUCCESS:
            return {
                ...state,
                loading: false,
                disabledOatmealDates: action.payload
            };

        case actionTypes.FETCH_DISABLED_OATMEAL_DATES_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            };

        default:
            return state;
    }
};

export default menuReducer;