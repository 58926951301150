import { RegisterData, RegisterDataErrors } from "../Auth";
import { validateEmail } from "../../../helpers/validation";

export const validateForm = (registerData: RegisterData): { valid: boolean; errors: RegisterDataErrors } => {
    const errors: RegisterDataErrors = {};

    //Validate email
    if (!registerData.email.trim()) {
        errors.email = "Polje je obavezno.";
    } else if (!validateEmail(registerData.email)) {
        errors.email = "Pogrešan format email adrese.";
    }

    //Validate customer
    if (!registerData.customer.trim()) {
        errors.customer = "Polje je obavezno.";
    }

    //Validate password
    if (!registerData.password.trim()) {
        errors.password = "Polje je obavezno.";
    } else if (registerData.password.length < 8) {
        errors.password = "Lozinka mora sadržati najmanje 8 karaktera.";
    }

    //Validate repeat password
    if (!registerData.repeatPassword.trim()) {
        errors.repeatPassword = "Polje je obavezno.";
    } else if (registerData.password !== registerData.repeatPassword) {
        errors.repeatPassword = "Lozinke se ne podudaraju.";
    }

    //Validate terms of use
    if (!registerData.termsOfUse) {
        errors.termsOfUse = "Polje je obavezno.";
    }

    return {
        valid: Object.keys(errors).length === 0,
        errors
    };
};