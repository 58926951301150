import React from "react";
import { Autocomplete as MuiAutocomplete, TextField, FormControl } from "@mui/material";

interface CustomAutocompleteProps<T> {
    options: T[];
    label: string;
    value: T | null;
    onChange: (event: React.ChangeEvent<{}>, value: T | null) => void;
    getOptionLabel: (option: T) => string;
    renderOption?: (props: React.HTMLAttributes<HTMLLIElement>, option: T) => React.ReactNode;
}

const Autocomplete = <T,>({ options, label, value, onChange, getOptionLabel, renderOption }: CustomAutocompleteProps<T>) => {
    return (
        <FormControl fullWidth sx={{ my: 1.5 }}>
            <MuiAutocomplete
                options={options}
                getOptionLabel={getOptionLabel}
                value={value}
                onChange={onChange}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={label}
                        variant="outlined"
                        sx={{
                            "& label.Mui-focused": {
                                color: "#6c757d",
                            },
                            "& .MuiFormLabel-asterisk": {
                                color: "#ff0000",
                            },
                            "& .MuiOutlinedInput-root": {
                                background: "#fff",
                                "&:hover fieldset": {
                                    border: "1px solid #6c757d",
                                },
                                "&.Mui-focused fieldset": {
                                    border: "1px solid #6c757d",
                                },
                            },
                        }}
                    />
                )}
                renderOption={renderOption}
            />
        </FormControl>
    );
};

export default Autocomplete;