import React, { FC } from "react";
import { Link } from "react-router-dom";

const Logo: FC = () => {
    return (
        <div>
            <Link to="/">
                <img src="./logos/logo.png" alt="logo" className="logo_img" />
            </Link>
        </div>
    );
};

export default Logo;