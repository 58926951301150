import { Customer } from "../../components/auth/Auth";
import { User } from "../reducers/authState";

export const REGISTER_USER_REQUEST = "REGISTER_USER_REQUEST";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_FAIL = "REGISTER_USER_FAIL";

export const LOGIN_USER_REQUEST = "LOGIN_USER_REQUEST";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_FAIL = "LOGIN_USER_FAIL";

export const CHECK_AUTH_STATE_REQUEST = "CHECK_AUTH_STATE_REQUEST";
export const CHECK_AUTH_STATE_SUCCESS = "CHECK_AUTH_STATE_SUCCESS";
export const CHECK_AUTH_STATE_FAIL = "CHECK_AUTH_STATE_FAIL";

export const LOGOUT_USER_REQUEST = "LOGOUT_USER_REQUEST";
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS";
export const LOGOUT_USER_FAIL = "LOGOUT_USER_FAIL";

export const FETCH_CUSTOMERS_REQUEST = "FETCH_CUSTOMERS_REQUEST";
export const FETCH_CUSTOMERS_SUCCESS = "FETCH_CUSTOMERS_SUCCESS";
export const FETCH_CUSTOMERS_FAIL = "FETCH_CUSTOMERS_FAIL";

export interface RegisterUserRequestAction {
    type: typeof REGISTER_USER_REQUEST;
}

export interface RegisterUserSuccessAction {
    type: typeof REGISTER_USER_SUCCESS;
    payload: string;
}

export interface RegisterUserFailAction {
    type: typeof REGISTER_USER_FAIL;
    payload: Error;
}

export interface LoginUserRequestAction {
    type: typeof LOGIN_USER_REQUEST;
}

export interface LoginUserSuccessAction {
    type: typeof LOGIN_USER_SUCCESS;
    payload: {
        token: string,
        uid: string,
        userData: User
    };
}

export interface LoginUserFailAction {
    type: typeof LOGIN_USER_FAIL;
    payload: Error;
}

export interface CheckAuthStateRequestAction {
    type: typeof CHECK_AUTH_STATE_REQUEST;
}

export interface CheckAuthStateSuccessAction {
    type: typeof CHECK_AUTH_STATE_SUCCESS;
    payload: {
        token: string,
        uid: string,
        userData: User
    };
}

export interface CheckAuthStateFailAction {
    type: typeof CHECK_AUTH_STATE_FAIL;
    payload: Error;
}

export interface LogoutUserRequestAction {
    type: typeof LOGOUT_USER_REQUEST;
}

export interface LogoutUserSuccessAction {
    type: typeof LOGOUT_USER_SUCCESS;
}

export interface LogoutUserFailAction {
    type: typeof LOGOUT_USER_FAIL;
    payload: Error;
}

export interface FetchCustomersRequestAction {
    type: typeof FETCH_CUSTOMERS_REQUEST;
}

export interface FetchCustomersSuccessAction {
    type: typeof FETCH_CUSTOMERS_SUCCESS;
    payload: Customer[];
}

export interface FetchCustomersFailAction {
    type: typeof FETCH_CUSTOMERS_FAIL;
    payload: Error;
}

export type ActionTypes =
    | RegisterUserRequestAction
    | RegisterUserSuccessAction
    | RegisterUserFailAction
    | LoginUserRequestAction
    | LoginUserSuccessAction
    | LoginUserFailAction
    | CheckAuthStateRequestAction
    | CheckAuthStateSuccessAction
    | CheckAuthStateFailAction
    | LogoutUserRequestAction
    | LogoutUserSuccessAction
    | LogoutUserFailAction
    | FetchCustomersRequestAction
    | FetchCustomersSuccessAction
    | FetchCustomersFailAction;