import { FC } from "react";
import Menu from "../components/Menu/Menu";
import Login from "../components/auth/Login/Login";
import Register from "../components/auth/Register/Register";
import Users from "../components/Users/Users";
import Settings from "../components/Settings/Settings";
import Reports from "../components/Reports/Reports";
import Orders from "../components/Orders/Orders";
import Contact from "../components/Contact/Contact";

interface RouteType {
    path: string;
    component: FC;
    protected: boolean;
}

const routes: RouteType[] = [
    {
        path: "/",
        component: Menu,
        protected: false
    },
    {
        path: "/contact",
        component: Contact,
        protected: false
    },
    {
        path: "/login",
        component: Login,
        protected: false
    },
    {
        path: "/register",
        component: Register,
        protected: false
    },
    {
        path: "/users",
        component: Users,
        protected: true
    },
    {
        path: "/settings",
        component: Settings,
        protected: true
    },
    {
        path: "/reports",
        component: Reports,
        protected: true
    },
    {
        path: "/orders",
        component: Orders,
        protected: true
    }
];

export default routes;