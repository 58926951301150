import React, { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { state } from "../../index";
import * as actions from "../../store/actions/settingsActions";
import settingsState from "../../store/reducers/settingsState";
import { Meal } from "../../store/reducers/menuState";
import Loader from "../UI/Loader";
import MealsForm from "./MealsForm";
import MenuForm from "./MenuForm";
import AddOrder from "./AddOrder";
import DisableOatmealOrderingForm from "./DisableOatmealOrderingForm";
import { Container } from "react-bootstrap";
import { Divider } from "@mui/material";

interface Section {
    title: string;
    component: JSX.Element;
}

const Settings: FC = () => {
    const dispatch = useDispatch();
    const { fetchMeals } = bindActionCreators(actions, dispatch);

    const settingsState: settingsState = useSelector((state: state) => state.settings);
    const meals: Meal[] = settingsState.meals.slice().sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0));;

    useEffect(() => {
        fetchMeals();
    }, []);

    if (settingsState.loading) return <Loader />;

    const sections: Section[] = [
        { title: "Kreiranje jela", component: <MealsForm meals={meals} /> },
        { title: "Kreiranje obroka", component: <MenuForm meals={meals} /> },
        { title: "Dodavanje jela za tekući dan", component: <AddOrder /> },
        { title: "Zabrana naručivanja ovsenih kaša", component: <DisableOatmealOrderingForm /> }
    ];

    return (
        <Container style={{ marginTop: 40 }}>
            {sections.map((section, i) => (
                <React.Fragment key={i}>
                    <Divider sx={{ mb: { xs: 1, lg: 3 }, mt: i > 0 ? { xs: 2, lg: 5 } : 0 }}>
                        <h3 className="main_title">{section.title}</h3>
                    </Divider>
                    {section.component}
                </React.Fragment>
            ))}
        </Container>
    );
};

export default Settings;