import React, { FC } from "react";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import * as menuActions from "../../../store/actions/menuActions";
import { Box, Button, Divider, Modal, ModalDialog, Typography } from "@mui/joy";
import { Delete, WarningRounded } from "@mui/icons-material";
import { toast } from "react-toastify";

interface OrderModalProps {
    open: boolean;
    selectedOrderId: string;
    selectedDate: string;
    userId: string;
    canDeleteOrder: boolean;
    onHandleClose: () => void;
}

const DeleteOrderModal: FC<OrderModalProps> = ({ open, selectedOrderId, selectedDate, userId, canDeleteOrder, onHandleClose }) => {
    const dispatch = useDispatch();
    const { deleteUserOrder } = bindActionCreators(menuActions, dispatch);

    const handleDeleteOrder = (e: React.FormEvent): void => {
        if (canDeleteOrder) {
            e.preventDefault();
            deleteUserOrder(selectedDate, userId, selectedOrderId);
            onHandleClose();
        } else {
            toast.error("Porudžbinu nije moguće obrisati.");
        }
    };

    return (
        <Modal open={open} onClose={onHandleClose}>
            <ModalDialog
                variant="outlined"
                role="alertdialog"
                aria-labelledby="alert-dialog-modal-title"
                aria-describedby="alert-dialog-modal-description"
            >
                <Typography id="alert-dialog-modal-title" level="h2" startDecorator={<WarningRounded />}>
                    Brisanje porudžbine
                </Typography>
                <Divider />
                <Typography id="alert-dialog-modal-description" textColor="text.tertiary">
                    Da li ste sigurni da želite da obrišete porudžbinu?
                </Typography>
                <Box sx={{ display: "flex", gap: 1, justifyContent: "flex-end", pt: 2 }}>
                    <Button
                        variant="plain"
                        color="neutral"
                        onClick={() => onHandleClose()}
                    >
                        Otkaži
                    </Button>
                    <Button
                        variant="solid"
                        color="danger"
                        startDecorator={<Delete />}
                        onClick={handleDeleteOrder}
                    >
                        Obriši porudžbinu
                    </Button>
                </Box>
            </ModalDialog>
        </Modal>
    );
}

export default DeleteOrderModal;