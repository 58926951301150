import React, { FC } from "react";
import { Divider, Modal, ModalDialog, ModalClose, ModalOverflow, Typography } from "@mui/joy";

interface UserModalProps {
    open: boolean;
    onHandleClose: () => void;
}

const TermsOfUseModal: FC<UserModalProps> = ({ open, onHandleClose }) => {
    return (
        <Modal open={open} onClose={onHandleClose}>
            <ModalOverflow>
                <ModalDialog
                    variant="outlined"
                    role="alertdialog"
                    aria-labelledby="alert-dialog-modal-title"
                    aria-describedby="alert-dialog-modal-description"
                >
                    <ModalClose variant="outlined" />
                    <Typography
                        id="alert-dialog-modal-title"
                        level="h2"
                    >
                        Uslovi korišćenja i politika privatnosti
                    </Typography>
                    <div>
                        <p>"Uslovi korišćenja" odnose se na korišćenje Ukusno i tačka servisa i web stranica (u daljem tekstu Ukusno i tačka) i svih ponuđenih usluga.
                            Uslovi korišćenja primenjuju se na sve sadržaje i usluge. Pristupom i korišćenjem ovih stranica i servisa prihvatate sve uslove koji slede i
                            smatra se da ste upoznati sa istima u celini, kao i svim rizicima koji mogu nastati usled korišćenja.
                            Samim pristupom (registracijom), prihvatate uslove korišćenja sadržaja Ukusno i tačka servisa, isključivo za ličnu upotrebu i na sopstvenu odgovornost.</p>
                        <Divider sx={{ my: 1 }}>
                            <h3 className="main_title">Pravo na izmene sadržaja i usluga</h3>
                        </Divider>
                        <p>Ukusno i tačka zadržava pravo da u bilo koje vreme i/ili iz bilo kog razloga privremeno ili trajno modifikuje ili ukine ovaj web-sajt ili
                            usluge koje putem njih nudi, sa prethodnim obaveštenjem ili bez njega. Ukusno i tačka neće biti odgovoran ni vama niti nekoj trećoj strani za promenu ili
                            ukidanje bilo koje od ovih stranica ili usluga.</p>
                        <Divider sx={{ my: 1 }}>
                            <h3 className="main_title">Registrovani korisnici</h3>
                        </Divider>
                        <p> Registrovanjem na Ukusno i tačka servis korisnik se obavezuje da će ispuniti celokupan postupak registracije, uneti istinite zatražene podatke.</p>
                        <p>Za sve objavljene sadržaje pod pojedinim korisničkim imenom odgovoran je isključivo korisnik koji ga koristi. Ukusno i tačka se ne može ni na koji način smatrati
                            odgovornom za eventualno neovlašćeno korišćenje podataka, niti eventualnu štetu nastalu na taj način.</p>
                        <p>Ukusno i tačka zadržava pravo ukidanja ili uskraćivanja mogućnosti korišćenja korisničkog naloga sa koga je uočena zloupotreba ili korišćenje protivno opštim
                            uslovima propisanim od strane Ukusno i tačka servisa bez prethodne najave ili/i objašnjenja. Ukusnko i tačka ne snosi odgovornost za
                            štetu nastalu ukidanjem korisničkog naloga ili/i jedne ili više usluga za koje je korisnik registrovan.</p>
                        <Divider sx={{ my: 1 }}>
                            <h3 className="main_title">Privatnost podataka</h3>
                        </Divider>
                        <p>Ukusno i tačka poštuje privatnost svojih korisnika i u svemu poštuje odredbe Zakona o zaštiti podataka o ličnosti. Podatke koje korisnik ostavi na strani
                            Ukusnko i tačka, te ostale podatke o korisniku, Ukusno i tačka neće davati na uvid trećim stranama sem u zakonu izričito predviđenim slučajevima. Ukusno i tačka se obavezuje da
                            čuva privatnost ličnih podataka korisnika web-stranice, osim u slučaju teškog kršenja ovih uslova korišćenja ili nezakonitih aktivnosti korisnika.
                            Korisnicima Ukusno i tačka servisa se strogo zabranjuje, u skladu sa ovim pravilima korišćenja:</p>
                        <ul>
                            <li>objavljivanje, slanje i razmena sadržaja kojim se krše važeći zakoni Republike Srbije i/ili međunarodni zakoni, sadržaji koji su uvredljivi, vulgarni,
                                preteći, rasistički ili šovinistički, te štetni na bilo koji drugi način i/ili predstavljaju ugrožavanje bilo kog prava trećih lica;</li>
                            <li>objavljivanje, slanje i razmena informacija za koje korisnik zna ili pretpostavlja da su lažne, a čije bi korišćenje moglo naneti štetu drugim korisnicima
                                ili trećim licima</li>
                            <li>objavljivanje, slanje i razmenu sadržaja koji su zaštićeni Zakonom o autorskim i srodnim pravima;</li>
                            <li>svesno objavljivanje, slanje i razmena sadržaja koji sadrži viruse ili slične računarske podatke ili programe sačinjene u svrhu uništavanja ili ograničavanja rada bilo kojeg računarskog softvera i/ili hardvera i
                                telekomunikacione opreme</li>
                            <li>prikupljanje, čuvanje i objavljivanje ličnih podataka drugih korisnika</li>
                        </ul>
                    </div>
                </ModalDialog>
            </ModalOverflow>
        </Modal>
    );
}

export default TermsOfUseModal;