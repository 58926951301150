import * as actionTypes from "../types/authActionTypes";
import authState from "./authState";
import { ActionTypes } from "../types/authActionTypes";

const initialState: authState = {
    user: {
        address: "",
        canOrderCurrentDay: false,
        customerId: "",
        customerName: "",
        customerSubvention: 0,
        deliveryTime: "",
        email: "",
        fullName: "",
        id: "",
        isActive: true,
        phoneNumber: "",
        role: "",
        userType: ""
    },
    customers: [],
    userUid: "",
    token: "",
    error: null
};

const authReducer = (state = initialState, action: ActionTypes) => {
    switch (action.type) {
        case actionTypes.REGISTER_USER_REQUEST:
            return {
                ...state,
            };

        case actionTypes.REGISTER_USER_SUCCESS:
            return {
                ...state,
                userUid: action.payload,
            };

        case actionTypes.REGISTER_USER_FAIL:
            return {
                ...state,
                error: action.payload,
            };

        case actionTypes.LOGIN_USER_REQUEST:
            return {
                ...state
            };

        case actionTypes.LOGIN_USER_SUCCESS:
            return {
                ...state,
                token: action.payload.token,
                userUid: action.payload.uid,
                user: {
                    ...state.user,
                    address: action.payload.userData.address,
                    canOrderCurrentDay: action.payload.userData.canOrderCurrentDay,
                    customerId: action.payload.userData.customerId,
                    customerName: action.payload.userData.customerName,
                    customerSubvention: action.payload.userData.customerSubvention,
                    deliveryTime: action.payload.userData.deliveryTime,
                    email: action.payload.userData.email,
                    fullName: action.payload.userData.fullName,
                    isActive: action.payload.userData.isActive,
                    phoneNumber: action.payload.userData.phoneNumber,
                    role: action.payload.userData.role,
                    userType: action.payload.userData.userType
                }
            };

        case actionTypes.LOGIN_USER_FAIL:
            return {
                ...state,
                error: action.payload
            };

        case actionTypes.CHECK_AUTH_STATE_REQUEST:
            return {
                ...state
            };

        case actionTypes.CHECK_AUTH_STATE_SUCCESS:
            return {
                ...state,
                token: action.payload.token,
                userUid: action.payload.uid,
                user: {
                    ...state.user,
                    address: action.payload.userData.address,
                    canOrderCurrentDay: action.payload.userData.canOrderCurrentDay,
                    customerId: action.payload.userData.customerId,
                    customerName: action.payload.userData.customerName,
                    customerSubvention: action.payload.userData.customerSubvention,
                    deliveryTime: action.payload.userData.deliveryTime,
                    email: action.payload.userData.email,
                    fullName: action.payload.userData.fullName,
                    isActive: action.payload.userData.isActive,
                    phoneNumber: action.payload.userData.phoneNumber,
                    role: action.payload.userData.role,
                    userType: action.payload.userData.userType
                }
            };

        case actionTypes.CHECK_AUTH_STATE_FAIL:
            return {
                ...state,
                user: null,
                customerId: "",
                userUid: "",
                token: "",
                error: null
            };

        case actionTypes.LOGOUT_USER_REQUEST:
            return {
                ...state,
            };

        case actionTypes.LOGOUT_USER_SUCCESS:
            return {
                ...state,
                user: null,
                customerId: "",
                userUid: "",
                token: "",
                error: null
            };

        case actionTypes.LOGOUT_USER_FAIL:
            return {
                ...state,
                error: action.payload
            };

        case actionTypes.FETCH_CUSTOMERS_REQUEST:
            return {
                ...state
            };

        case actionTypes.FETCH_CUSTOMERS_SUCCESS:
            return {
                ...state,
                customers: action.payload
            };

        case actionTypes.FETCH_CUSTOMERS_FAIL:
            return {
                ...state,
                error: action.payload
            };

        default:
            return state;
    }
};

export default authReducer;