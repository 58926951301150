import React, { FC } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { state } from "./index";
import authState from "./store/reducers/authState";

interface ProtectedRouteProps {
    children: JSX.Element;
}

const ProtectedRoute: FC<ProtectedRouteProps> = ({ children }) => {
    const location = useLocation();
    const authState: authState = useSelector((state: state) => state.auth);
    const userUid: string = authState.userUid;

    if (!userUid) {
        return <Navigate to="/" state={{ from: location }} replace />
    }

    return children
};

export default ProtectedRoute;