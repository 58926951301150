import React, { FC } from "react";
import moment, { Moment } from "moment";
import "moment/locale/sr";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker as MuiDatePicker, LocalizationProvider, DateValidationError, PickerChangeHandlerContext } from "@mui/x-date-pickers";
import { FormControl } from "@mui/material";

interface DatePickerProps {
    label?: string;
    value: Moment;
    minDate?: Moment;
    maxDate?: Moment;
    onChange: (value: any, context: PickerChangeHandlerContext<DateValidationError>) => void;
}

moment.updateLocale("en", {
    week: { dow: 1 }
});

const DatePicker: FC<DatePickerProps> = ({ label, value, minDate, maxDate, onChange }) => {
    const disableWeekends = (date: Moment): boolean => {
        return date.day() === 0 || date.day() === 6;
    }

    return (
        <FormControl sx={{ my: 1.5 }}>
            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="sr">
                <MuiDatePicker
                    label={label}
                    value={value}
                    minDate={minDate}
                    maxDate={maxDate}
                    format="DD/MM/YYYY"
                    dayOfWeekFormatter={(day) => `${day}`}
                    shouldDisableDate={disableWeekends}
                    onChange={onChange}
                    sx={{
                        "& label.Mui-focused": {
                            color: "#6c757d"
                        },
                        "& .MuiOutlinedInput-root": {
                            background: "#fff",
                            "&:hover fieldset": {
                                border: "1px solid #6c757d"
                            },
                            "&.Mui-focused fieldset": {
                                border: "1px solid #6c757d"
                            }
                        }
                    }}
                />
            </LocalizationProvider>
        </FormControl>
    );
};

export default DatePicker;