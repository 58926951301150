import React, { FC, useState } from "react";
import { OutlinedInput, InputAdornment, IconButton, FormControl, InputLabel } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

interface PasswordInputProps {
    label: string;
    value: string | number;
    name: string;
    required?: boolean;
    error?: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onKeyUp?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}

const PasswordInput: FC<PasswordInputProps> = ({ label, value, name, required = false, error, onChange, onKeyUp }) => {
    const [showPassword, setShowPassword] = useState<boolean>(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    return (
        <FormControl fullWidth sx={{ my: 1.5 }}>
            <InputLabel
                required={required}
                htmlFor="outlined-adornment-password"
                sx={{
                    "&.Mui-focused": {
                        color: "#6c757d"
                    },
                    "& .MuiFormLabel-asterisk": {
                        color: "#ff0000"
                    }
                }}
            >
                {label}
            </InputLabel>
            <OutlinedInput
                label={label}
                type={showPassword ? "text" : "password"}
                value={value}
                name={name}
                size="medium"
                onChange={onChange}
                onKeyUp={onKeyUp}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            edge="end"
                            onClick={handleClickShowPassword}
                        >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                }
                sx={{
                    "&.MuiOutlinedInput-root": {
                        "&:hover fieldset": {
                            border: "1px solid #6c757d"
                        },
                        "&.Mui-focused fieldset": {
                            border: "1px solid #6c757d"
                        }
                    }
                }}
            />
            {error && <div className="error_message">{error}</div>}
        </FormControl>
    );
};

export default PasswordInput;